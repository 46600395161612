import { JsonName } from 'tserialize';
import { BaseModel } from '../base.model';

export class ShowcaseBlockModel extends BaseModel {
  @JsonName()
  code?: string;

  constructor(data: object) {
    super(data);

    Object.assign(this, data);
  }
}