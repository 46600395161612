export enum SidebarActionType {
  CHANGE_SIDEBAR_VISIBILITY = 'CHANGE_SIDEBAR_VISIBILITY',
  CHANGE_MOBILE_SIDEBAR_VISIBILITY = 'CHANGE_MOBILE_SIDEBAR_VISIBILITY',
  SHOW_SIDEBAR = 'SHOW_SIDEBAR'
}

export type TSidebarAction = { type: SidebarActionType };

export function changeSidebarVisibility() {
  return {
    type: SidebarActionType.CHANGE_SIDEBAR_VISIBILITY,
  };
}

export function changeMobileSidebarVisibility() {
  return {
    type: SidebarActionType.CHANGE_MOBILE_SIDEBAR_VISIBILITY,
  };
}

export function showSidebar() {
  return {
    type: SidebarActionType.SHOW_SIDEBAR,
  };
}
