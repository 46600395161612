/* eslint-disable camelcase */
import common_he from './he/common.json';
import common_en from './en/common.json';
import common_ru from './ru/common.json';

import errors_he from './he/errors.json';
import errors_en from './en/errors.json';
import errors_ru from './ru/errors.json';

const translationDeclaration = {
  en: {
    common: common_en,
    errors: errors_en,
  },
  he: {
    common: common_he,
    errors: errors_he,
  },
  ru: {
    common: common_ru,
    errors: errors_ru,
  },
};

export default translationDeclaration;