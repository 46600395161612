import React, { useMemo } from 'react';
import { Observable } from 'rxjs';
import classNames from "classnames";
import { useRx } from 'utils/hooks/use-rx';
import './form.scss';

type TControlProps = {
  label: string;
  children: JSX.Element | Array<JSX.Element>;
  error?: Observable<string>;
  horizontal?: boolean;
  leftAcc?: JSX.Element;
  rightAcc?: JSX.Element;
}

export function Control({ label, children, error, horizontal, leftAcc, rightAcc }: TControlProps): JSX.Element {
  const errorValue = useRx(error || new Observable<string>(), '');
  const formClassName = useMemo((): string => {
    return classNames('form', { 'form--horizontal': horizontal });
  }, [horizontal]);

  return (
    <div className={formClassName}>
      <label className="form__form-group">
        <span className="form__form-group-label">{label}</span>

        <div className="form__form-group-field">
          {leftAcc}

          <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
            {children}

            {errorValue && <span className="form__form-group-error">{errorValue}</span>}
          </div>

          {rightAcc}
        </div>
      </label>
    </div>
  );
}