import React from 'react';
import './spinner.scss';

type TSpinnerProps = {
  buttonSpinner?: boolean;
};

export function Spinner({ buttonSpinner } : TSpinnerProps): JSX.Element {
  return (
    <div className='spinner-wrapper'>
      <div className="spinner-container">
        {Array.from(Array(12).keys()).map(number => (
          <div 
            key={number} 
            className={`spinner spinner-${number} ${ buttonSpinner ? 'buttonSpinner' : null}`}/>
        ))}
      </div>
    </div>
  )
}