import { ErrorModel, PersonModel } from "api/models";
import { UserActionType, TUserAction } from "redux/actions/user.action";

type TUserState = { currentUser?: PersonModel | null, error?: ErrorModel };

const storedState = localStorage.currentUser ? JSON.parse(localStorage.currentUser) : null;

const initialState: TUserState = { 
  currentUser: storedState ? storedState : null,
};

export const userReducer = (state: TUserState = initialState, action: TUserAction): TUserState => {
  switch (action.type) {
    case UserActionType.SET_CURRENT_USER:
      return { ...state, currentUser: action.currentUser };
    case UserActionType.CURRENT_USER_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
};
