import { BusinessActionType, TBusinessAction } from 'redux/actions/business.action';
import { BusinessModel, ErrorModel } from "api/models";

type TBusinessState = { 
  businesses?: Array<BusinessModel>; 
  currentBusiness?: BusinessModel | null; 
  error?: ErrorModel 
};

const storedState: TBusinessState = localStorage.context ? JSON.parse(localStorage.context) : null;

const initialState: TBusinessState = {
  currentBusiness: storedState ? storedState.currentBusiness : null,
};

export const businessReducer = (state: TBusinessState = initialState, action: TBusinessAction): TBusinessState => {
  switch (action.type) {
    case BusinessActionType.SET_BUSINESSES:
      return { ...state, businesses: action.businesses };
    case BusinessActionType.BUSINESSES_ERROR:
      return { ...state, error: action.error };
    case BusinessActionType.CHOOSE_BUSINESS:
      localStorage.context = JSON.stringify({ currentBusiness: action.currentBusiness });

      return { ...state, currentBusiness: action.currentBusiness };
    case BusinessActionType.CLEAR_CURRENT_BUSINESS:
      localStorage.context = JSON.stringify({ currentBusiness: null, currentSite: null });

      return { ...state, currentBusiness: null};
    default:
      return state;
  }
};
