import { ErrorModel, SiteModel, UserPictureModel } from 'api/models';
import { SiteActionType, TSiteAction, clearSiteAndPages } from 'redux/actions/site.action';
import { SiteConfigModel } from 'api/models/site-config.model';
import ApiService from 'api/api.service';
import { store } from 'redux/store';

type TSiteState = { 
  sites?: Array<SiteModel>; 
  error?: ErrorModel;
  currentSite?: SiteModel | null;
  updatedSite?: SiteModel | null;
  isLoading: boolean;
};

const storedState = localStorage.context ? JSON.parse(localStorage.context ) : null;

const initialState: TSiteState = { 
  currentSite: storedState ? storedState.currentSite : null,
  updatedSite : storedState ? storedState.updatedSite || deepCopySite(storedState.currentSite) : null,
  isLoading: false,
};

function deepCopySite(currentSite?: SiteModel) {
  const updatedConfig = SiteConfigModel.fromServer({ ...(currentSite?.config || { }) });
  updatedConfig.logoPicture = currentSite?.config.logoPicture
    ? new UserPictureModel(currentSite.config.logoPicture)
    : undefined;
  updatedConfig.blocks = [...(currentSite?.config?.blocks || [])];

  return new SiteModel({ ...currentSite, config: updatedConfig });
}

function updateSiteForPreview(currentSite?: SiteModel | null): void {
  setTimeout(() => {
    const businessId = store.getState().business.currentBusiness?.id;

    if (!businessId || !currentSite) return;

    currentSite.config.setIsPreview();

    void ApiService.updateSite(businessId, currentSite.id, currentSite.config);
  })
}

export const siteReducer = (state: TSiteState = initialState, action: TSiteAction): TSiteState => {
  switch (action.type) {
    case SiteActionType.SET_SITES:
      return { ...state, sites: action.sites };
    case SiteActionType.SITES_ERROR:
      return { ...state, error: action.error };
    case SiteActionType.SITE_REQUEST_STARTED:
      return { ...state, isLoading: true };
    case SiteActionType.SITE_REQUEST_FINISHED:
      return { ...state, isLoading: false };
    case SiteActionType.CHOOSE_SITE:
    case SiteActionType.CREATE_SITE:
    case SiteActionType.PUBLISH_SITE:
      const currentSite = deepCopySite(action.currentSite)
      const updatedSite = deepCopySite(action.currentSite);

      return { ...state, isLoading: false, currentSite, updatedSite };
    case SiteActionType.CLEAR_CURRENT_SITE:
      clearSiteAndPages()
      return { ...state }
    case SiteActionType.ADD_SITE_BLOCK:
      state.updatedSite?.config.addBlock(action.block, action.blockIndex);
      updateSiteForPreview(state.updatedSite);

      return { ...state };
    case SiteActionType.REMOVE_SITE_BLOCK:
      state.updatedSite?.config.removeBlock(action.blockIndex);
      updateSiteForPreview(state.updatedSite);

      return { ...state };
    case SiteActionType.UPDATE_SITE_BLOCK:
      state.updatedSite?.config.updateBlock(action.block, action.blockIndex);
      updateSiteForPreview(state.updatedSite);

      return { ...state };
    case SiteActionType.MOVE_BLOCK_UP:
      state.updatedSite?.config.moveBlockUp(action.blockIndex);
      updateSiteForPreview(state.updatedSite);

      return { ...state };
    case SiteActionType.MOVE_BLOCK_DOWN:
      state.updatedSite?.config.moveBlockDown(action.blockIndex);
      updateSiteForPreview(state.updatedSite);

      return { ...state };
    default:
      return { ...state };
  }
};
