import React from 'react';
import './grid.scss';
import classNames from "classnames";

type TContent = JSX.Element | undefined | null | string | boolean;

type TGridProps = {
  children: TContent | Array<TContent>;
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
  align?: 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around';
  justify?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly';
  columns?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12'
  grow?: number;
  shrink?: number;
  gap?: number;
}

export function Grid({ children, direction, align, justify, columns, grow, shrink, gap }: TGridProps): JSX.Element {
  const className = classNames('ps-grid', {
    'ps-grid__table': columns,
    [`ps-grid__columns-${columns}`]: columns
  });

  return (
    <div
      className={className}
      style={{
        justifyContent: justify,
        flexDirection: direction,
        alignItems: align,
        flexWrap: columns ? 'wrap' : 'nowrap',
        flexShrink: shrink,
        flexGrow: grow,
        gap
      }}
    >
      {children}
    </div>
  );
}