import { useForm } from './use-form';
import React, { TextareaHTMLAttributes } from 'react';
import { FormService, TFormService } from 'utils/form/form.service';
import { Grid } from 'ui-kit/grid';

type TFormTextareaProps<T extends TFormService<T>> = TextareaHTMLAttributes<HTMLTextAreaElement>
& { service: FormService<T>; fieldName: keyof T; withLanguage?: boolean };

export function Textarea<T extends TFormService<T>>(
  { service, fieldName, onFocus, onChange, onBlur, withLanguage, ...rest }: TFormTextareaProps<T>
): JSX.Element {
  const { value, onFocusFn, onChangeFn, onBlurFn, languageButtons, disabled } = useForm({ service, fieldName, onFocus, onChange, onBlur, withLanguage });

  return <Grid direction="column">
    <textarea
      {...rest}
      value={value as unknown as string || ''}
      onFocus={onFocusFn}
      onChange={onChangeFn}
      onBlur={onBlurFn}
      disabled={disabled}
    />

    {languageButtons}
  </Grid>;
}
