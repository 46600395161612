import React from 'react';
import { Modal } from 'ui-kit/modal';
import { Text } from 'ui-kit/text';
import { useRx } from 'utils/hooks/use-rx';
import { loginService } from './login.service';
import { LoginPage } from './login-page.enum';
import { LoginForm, RegistrationForm } from './pages';
import { useSelector } from 'react-redux';
import { TStore } from 'redux/store';

const setCurrentLoginPageCallback = loginService.setCurrentLoginPageCallback(LoginPage.LOGIN);

export function LoginModal(): JSX.Element {
  const currentPage = useRx(loginService.currentPage$, LoginPage.LOGIN);
  const direction = useSelector((store: TStore) => store.rtl.direction);

  return (
    <Modal
      isCentered
      direction={direction}
      header={<Text size="24" weight="400">Pinsteps Studio</Text>}
      subheader={<Text size="16" weight="400">Start Your business easily</Text>}
      backAction={currentPage === LoginPage.REGISTRATION && setCurrentLoginPageCallback}
    >
      {currentPage === LoginPage.LOGIN ? <LoginForm/> : <RegistrationForm/>}
    </Modal>
  );
}
