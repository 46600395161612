import { JsonName, JsonNameLate } from 'tserialize';
import { SiteBlock } from 'enums/site-block.enum';
import { BaseModel } from '../base.model';
import { RouteBlockModel } from './route-block.model';
import { MilestoneBlockModel } from './milestone-block.model';
import { ShowcaseBlockModel } from './showcase-block.model';
import { CoverBlockModel } from './cover-block.model';
import { TextBlockModel } from './text-block.model';
import { PlaneHtmlBlockModel } from './plane-html-block.model';

export type TSiteBlockContent = RouteBlockModel | MilestoneBlockModel | ShowcaseBlockModel | CoverBlockModel | TextBlockModel | PlaneHtmlBlockModel;

const MODELS: Partial<Record<SiteBlock, typeof BaseModel>> = {
  [SiteBlock.ROUTE]: RouteBlockModel,
  [SiteBlock.MILESTONE]: MilestoneBlockModel,
  [SiteBlock.SHOWCASE]: ShowcaseBlockModel,
  [SiteBlock.COVER]: CoverBlockModel,
  [SiteBlock.TEXT]: TextBlockModel,
  [SiteBlock.HTML]: PlaneHtmlBlockModel,
};

function deserializeContent(value: object, data: SlideBlockModel): TSiteBlockContent {
  return MODELS[data.type]?.fromServer(value) as TSiteBlockContent;
}

function serializeContent(value: TSiteBlockContent, data: SlideBlockModel): object {
  // @ts-ignore
  return new MODELS[data.type]({ ...value }).toServer();
}

export class SlideBlockModel extends BaseModel {
  @JsonName()
  type!: SiteBlock;

  @JsonNameLate('content', serializeContent, deserializeContent)
  content!: TSiteBlockContent;

  constructor(data: object) {
    super(data);

    Object.assign(this, data);
  }
}
