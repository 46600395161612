import { RouteActionType, TRouteAction } from 'redux/actions/route.action';
import { PAGE, PAGE_BLOCK_SETTINGS } from 'routers/page.enum';
import { SiteBlockModel } from 'api/models';

type TSiteState = {
  currentPage?: PAGE | PAGE_BLOCK_SETTINGS | null;
  blockIndex?: number;
  blockData?: SiteBlockModel;
  backAction?: VoidFunction;
  saveAction?: (config: object, initialData?: SiteBlockModel, blockIndex?: number) => VoidFunction;
};

const storedState = localStorage.context ? JSON.parse(localStorage.context ) : null;
const initialState: TSiteState = { currentPage: storedState ? storedState.currentPage : null };

export const routeReducer = (state: TSiteState = initialState, action: TRouteAction): TSiteState => {
  switch (action.type) {
    case RouteActionType.GO_TO_PAGE:
      action.currentPage && window.history.pushState({ currentPage: action.currentPage }, 'currentPage');
      return { ...state, currentPage: action.currentPage };
    case RouteActionType.REDIRECT_TO_PAGE:
      action.currentPage && window.history.replaceState({ currentPage: action.currentPage }, 'currentPage');
      return { ...state, currentPage: action.currentPage };
    case RouteActionType.GO_TO_PAGE_BLOCK_SETTINGS:
      action.currentPage && window.history.pushState({ currentPage: action.currentPage }, 'currentPage');
      return { ...state, ...action };

    default:
      return state;
  }
};
