import { Layout } from './layout';
import React, { LazyExoticComponent, Suspense } from 'react';
import MainWrapper from '../wrappers/main-wrapper';
import { Spinner } from "ui-kit/spinner";

type TPageLayoutProps = {
  component?: JSX.Element;
};

export default function PageLayout({ component: Component }: TPageLayoutProps) {
  return (
    <MainWrapper>
      <div>
        <Layout />
        <div className="container__wrap">
          <Suspense fallback={<Spinner/>}>
            {Component}
          </Suspense>
        </div>
      </div>  
    </MainWrapper>
  )
}
