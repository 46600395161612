import { PagePreviewModel, PageModel, ErrorModel } from "api/models"
import { LanguageModel } from "api/models/language.model";
import { PageActionType, TPageAction } from "redux/actions/page.actions";

type TPageState = {
  pages: Array<PagePreviewModel>,
  currentPage: PageModel | null,
  languages: Array<LanguageModel>,
  currentLanguage?: string,
  loading?: boolean,
  error: ErrorModel | null,
};

const initialState: TPageState = {
  pages: [],
  currentPage: null,
  languages: [],
  currentLanguage: undefined,
  loading: false,
  error: null,
};

export const pageReducer = (
  state: TPageState = initialState,
  action: TPageAction
): TPageState => {
  const newState = { ...state };
  newState.error = ('error' in action && action.error) || null;
  
  switch (action.type) {
    case PageActionType.SET_PAGES:
      return { ...newState, pages: action.pages };
    case PageActionType.CHANGE_SITE_PAGE:
      return { ...newState, currentPage: action.currentPage };
    case PageActionType.CLOSE_SITE_PAGE:
      return { ...newState, currentPage: null };
    case PageActionType.CLEAR_PAGES:
      return { ...newState, pages: [], currentPage: null };
    case PageActionType.PAGES_START_LOADING:
      return { ...newState, loading: true };
    case PageActionType.PAGES_STOP_LOADING:
      return { ...newState, loading: false };
    case PageActionType.SET_ERROR:
      return { ...newState, error: action.error };
    case PageActionType.SWITCH_TO_LANGUAGE:
      return { ...newState, currentLanguage: action.language };
  }

  return state;
}