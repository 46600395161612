import { BaseModel } from 'api/models/base.model';
import { JsonName } from 'tserialize';
import { TLText } from 'types/text';

export class TextBlockModel extends BaseModel {
  @JsonName()
  title!: TLText;

  @JsonName()
  description?: TLText;

  @JsonName('class_name')
  className?: string;

  constructor(data: object) {
    super(data);

    Object.assign(this, data);
  }
}
