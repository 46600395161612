import { useTranslation } from 'react-i18next';
import { uiKitService } from 'ui-kit/ui-kit.service';
import { LoginModal } from 'pages/login';
import { Button } from 'ui-kit/button';

export function LogIn(): JSX.Element {
  const { t } = useTranslation('common');

  return <Button onClick={() => uiKitService.openModal(<LoginModal/>)}>{t("buttons.logIn")}</Button>;
}
