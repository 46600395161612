import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { TStore } from "redux/store";

type TMainWrapperProps = PropsWithChildren<RouteComponentProps> & {
  theme: string;
  direction: string;
};

const MainWrapper = ({ theme, children, direction }: TMainWrapperProps) => {
  return (
    <div className={`${theme} ${direction || 'rtl'}-support`} dir={direction || 'rtl'}>
      <div className="wrapper">
        {children}
      </div>
    </div>
  );
};

export default withRouter(connect((store: TStore) => ({
  theme: store.theme.className,
  direction: store.rtl.direction,
}))(MainWrapper));
