import { JsonName } from "tserialize";
import { BaseModel } from "./base.model";


export class PagePreviewModel extends BaseModel {
  @JsonName()
  url!: string;

  @JsonName()
  title!: string;
}