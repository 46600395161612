import { ReactElement, useEffect } from 'react';
import { RouteComponentProps, withRouter } from "react-router-dom";

type TScrollToTopHocProps = RouteComponentProps & {
  children: ReactElement;
}

export const ScrollToTopHoc = withRouter(({ children, location }: TScrollToTopHocProps) => {
  useEffect(() => {
    if (location && location.pathname) {
      window.scrollTo(0, 0);
    }
  }, [location]);
  
  return children;
});
