import { JsonName } from "tserialize";
import { BaseModel } from "./base.model";
import { JsonTranslatable, type TTranslatableData } from "api/translatable.utils";
import { PageMetaModel } from "./page-meta.model";

export class TranslatablePageModel extends BaseModel {
  @JsonName()
  meta?: PageMetaModel;

  @JsonName()
  content?: Array<object>;
}

export class PageModel extends BaseModel {
  @JsonName()
  url!: string;

  @JsonName()
  title!: string;

  @JsonName()
  created!: string;

  @JsonName()
  updated!: string;

  @JsonTranslatable(TranslatablePageModel)
  translatable?: TTranslatableData<TranslatablePageModel>;
}