import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import TopbarLanguage from './TopbarLanguage';
import Logo from './Logo';
import './topbar.scss';

const Topbar = ({ changeMobileSidebarVisibility, changeSidebarVisibility }) => {
  const sidebarShouldshow = useSelector((store) => store.sidebar.isShownInLayout);
  
  return(
  <div className="topbar">
    <div className="topbar__wrapper">
      <div className="topbar__left">
        {
          sidebarShouldshow ? (
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
          ) : null
        }
        <Logo />
      </div>
      <div className="topbar__center">
        <TopbarProfile />
      </div>
      <div className="topbar__right">
        <TopbarLanguage />
      </div>
    </div>
  </div>
  )
};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;
