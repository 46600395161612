import { FormService, TFieldValidators } from 'utils/form/form.service';
import { store } from 'redux/store';
import { LoginRequest, LoginResponse } from 'api/models';
import ApiService from 'api/api.service';
import { authRequested, login } from 'redux/actions/auth.action';
import { setCurrentUser } from 'redux/actions/user.action';
import { uiKitService } from 'ui-kit/ui-kit.service';
import { requireValidator } from 'utils';
import i18n from 'translations/i18n';
import { goToPage } from 'redux/actions/route.action';
import { PAGE } from 'routers/page.enum';

type TLoginFormState = { email: string, password: string };

const LoginFormInitialState: TLoginFormState = { email: '', password: '' };

const LoginFormValidators: TFieldValidators<TLoginFormState> = {
  email: requireValidator(i18n.t('common:modals.login.formErrors.email')),
  password: requireValidator(i18n.t('common:modals.login.formErrors.password'))
};

class LoginForm extends FormService<TLoginFormState> {
  handleLogin = (): Promise<void> => {
    store.dispatch(authRequested());
    
    const { email, password } = this.formValues$.getValue();
    const loginRequest = new LoginRequest(email, password);
    
    return ApiService.login(loginRequest.email, loginRequest.password)
      .then((auth: LoginResponse) => {
        const { token, person } = auth;
        const { id } = person;

        store.dispatch(login(token));
        store.dispatch(setCurrentUser(person));

        localStorage.auth = JSON.stringify({ isLoggedIn: true, token });
        localStorage.currentUser = JSON.stringify(person);

        store.dispatch(goToPage(PAGE.BUSINESS_CHOICE));
        uiKitService.closeModal();
      })
  };
}

export const loginFormService = new LoginForm(LoginFormInitialState, LoginFormValidators);
