import { Control, Input } from 'utils/form';
import { registrationFormService } from './registration-form.service';
import { Offset } from 'ui-kit/offset';
import { Button } from 'ui-kit/button';
import { loginService } from '../../login.service';
import { LoginPage } from '../../login-page.enum';
import { Grid } from 'ui-kit/grid';
import { useRx } from 'utils/hooks/use-rx';
import { useTranslation } from 'react-i18next';

export function RegistrationForm(): JSX.Element {
  const isLoading = useRx(registrationFormService.isLoading$, false);
  const isFullValid = useRx(registrationFormService.isFullValid$(), false);

  const { t } = useTranslation('common');

  return <>
    <Control label={t('modals.registration.formLabels.email')} error={registrationFormService.getError$('email')}>
      <Input service={registrationFormService} fieldName="email"/>
    </Control>

    <Offset offset="20"/>

    <Control label={t('modals.registration.formLabels.password')} error={registrationFormService.getError$('password')}>
      <Input service={registrationFormService} fieldName="password"/>
    </Control>

    <Offset offset="20"/>

    <Control label={t('modals.registration.formLabels.firstName')} error={registrationFormService.getError$('firstName')}>
      <Input service={registrationFormService} fieldName="firstName"/>
    </Control>

    <Offset offset="20"/>

    <Control label={t('modals.registration.formLabels.lastName')} error={registrationFormService.getError$('lastName')}>
      <Input service={registrationFormService} fieldName="lastName"/>
    </Control>

    <Offset offset="20"/>

    <Control label={t('modals.registration.formLabels.nickname')} error={registrationFormService.getError$('nickname')}>
      <Input service={registrationFormService} fieldName="nickname"/>
    </Control>

    <Offset offset="20"/>

    <Control label={t('modals.registration.formLabels.description')} error={registrationFormService.getError$('description')}>
      <Input service={registrationFormService} fieldName="description"/>
    </Control>

    <Offset offset="35"/>

    <Grid gap={8}>
      <Button onClick={loginService.setCurrentLoginPageCallback(LoginPage.LOGIN)}>{t('buttons.back')}</Button>

      <Button
        disabled={!isFullValid}
        isLoading={isLoading}
        onClick={registrationFormService.registerUser}
        color="primary"
      >
        {t('buttons.register')}
      </Button>
    </Grid>
  </>;
}
