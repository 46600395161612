import { FormService, TFieldValue, TFormService, THTMLTextElement } from './form.service';
import { ChangeEvent, ChangeEventHandler, FocusEvent, FocusEventHandler, useCallback, useMemo, useState } from 'react';
import { useRx } from "utils/hooks/use-rx";
import { useSelector } from 'react-redux';
import { TStore } from 'redux/store';
import { Button } from 'ui-kit/button';
import { LanguageModel } from 'api/models/language.model';
import { Grid } from 'ui-kit/grid';

export type TCustomChangeFunction = (value?: string) => void;
export type TChangeFunction = ChangeEventHandler<THTMLTextElement>;
type TFocusFunction = FocusEventHandler<THTMLTextElement>;
type TBlurFunction = TFocusFunction;

type TUseFormArguments<T extends TFormService<T>> = {
  service: FormService<T>;
  fieldName: keyof T;
  onFocus?: TFocusFunction;
  onChange?: TChangeFunction;
  customOnChange?: TCustomChangeFunction;
  onBlur?: TBlurFunction;
  withLanguage?: boolean;
};

type TUseFormReturnValue<T> = {
  value: TFieldValue<T> | undefined;
  onFocusFn: TFocusFunction;
  onChangeFn: TChangeFunction;
  onBlurFn: TBlurFunction;
  customOnChangeFn: TCustomChangeFunction;
  languageButtons: JSX.Element;
  disabled: boolean;
  language?: string;
};

export function useForm<T extends TFormService<T>>(args: TUseFormArguments<T>): TUseFormReturnValue<T> {
  const languages = useSelector((store: TStore) => store.site.currentSite?.config.languages);
  const [language, setLanguage] = useState<string>(languages?.[0]?.language || '');
  const { service, fieldName, onFocus, onBlur, onChange, customOnChange, withLanguage } = args;

  const finalFieldName = useMemo(() => {
    return withLanguage ? `${fieldName}.${language}` : fieldName;
  }, [withLanguage, fieldName, language]);

  const disabled = useMemo(() => {
    return Boolean(withLanguage && (!languages || !languages.length));
  }, [withLanguage, languages]);

  const value = useRx(service.getValue$<TFieldValue<T>>(finalFieldName));

  const onFocusFn = useCallback((event: FocusEvent<THTMLTextElement>): void => {
    onFocus && onFocus(event);
    service.setStateOnInputFocus(finalFieldName);
  }, [service, finalFieldName, onFocus]);

  const onChangeFn = useCallback((event: ChangeEvent<THTMLTextElement>): void => {
    onChange && onChange(event);
    service.setValue(finalFieldName, event.target.value as unknown as TFieldValue<T>);
  }, [service, finalFieldName, onChange]);

  const customOnChangeFn = useCallback((value?: string): void => {
    customOnChange && customOnChange(value);
    service.setValue(finalFieldName, value as unknown as TFieldValue<T>);
  }, [service, finalFieldName, customOnChange]);

  const onBlurFn = useCallback((event: FocusEvent<THTMLTextElement>): void => {
    onBlur && onBlur(event);
    service.setStateOnInputBlur(finalFieldName);
  }, [service, finalFieldName, onBlur]);

  const languageButtons = useMemo((): JSX.Element => {
    return withLanguage ? (
      <Grid>
        {languages?.map((lang: LanguageModel) => (
          <Button
            key={lang.language}
            color={lang.language === language ? 'primary' : undefined}
            onClick={() => setLanguage(lang.language)}
          >
            {lang.title}
          </Button>
        ))}
      </Grid>
    ) : <></>;
  }, [withLanguage, languages, language]);

  return { value, onFocusFn, onChangeFn, onBlurFn, customOnChangeFn, languageButtons, disabled, language };
}
