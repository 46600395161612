import React, { CSSProperties, useMemo } from 'react';

type TOffsetValue = number | string | null;
type TEmptySpaceProps = { offset: TOffsetValue; children?: undefined; };
type TSpacedWrapperProps = { offset: Array<TOffsetValue>; children: JSX.Element | Array<JSX.Element>; };
type TOffsetProps = TEmptySpaceProps | TSpacedWrapperProps;

export function Offset({ offset, children }: TOffsetProps): JSX.Element {
  const styles = useMemo((): CSSProperties => {
    if (!children) {
      return { height: offset ? `${offset}px` : undefined, width: '100%' }
    }

    const cleanedOffset = (offset as Array<TOffsetValue>).map((value: TOffsetValue) => {
      return value ? `${value}px` : 0;
    });

    return { padding: `${cleanedOffset.join(' ')}`, height: '100%' }
  }, [children, offset]);

  return <div style={styles}>{children}</div>
}