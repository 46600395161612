import { JsonName, JsonStruct} from "tserialize";
import { BaseModel } from "./base.model";
import { AvatarModel } from "./avatar.model";
export class PersonModel extends BaseModel {
  @JsonName()
  description!: string;

  @JsonName('do_analytics')
  doAnalytics!: boolean;

  @JsonName('facebook_user_id')
  facebookUserId?: number;

  @JsonName('first_name')
  firstName!: string;

  @JsonName('full_name')
  fullName!: string;

  @JsonName()
  id!: number;

  @JsonName('last_name')
  lastName!: string;

  @JsonName()
  nickname?: string;

  @JsonStruct(AvatarModel)
  avatar?: AvatarModel;
  
  @JsonName()
  tracks?: Array<object>;
}