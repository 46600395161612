import React from 'react';
import { connect } from 'react-redux';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { TStore } from "redux/store";

type TThemeProps = {
  themeName: string;
  children: JSX.Element;
};

function Theme({ children, themeName }: TThemeProps): JSX.Element {
  const type = themeName === 'theme-dark' ? 'dark' : 'light';
  const theme = createTheme({ palette: { type } });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default connect((store: TStore) => ({ themeName: store.theme.className }))(Theme);
