import { JsonName } from 'tserialize';
import { BaseModel } from 'api/models/base.model';

export class PictureModel extends BaseModel {
  @JsonName()
  id!: number;

  @JsonName()
  name!: string;

  @JsonName()
  extension!: string;

  @JsonName()
  original!: string;

  @JsonName()
  crop!: string;

  @JsonName()
  thumbnail!: string;

  @JsonName('original_url')
  originalUrl!: string;

  @JsonName('crop_url')
  cropUrl!: string;

  @JsonName('thumbnail_url')
  thumbnailUrl!: string;
}