import { ChangeEvent } from 'react';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import set from 'lodash.set';
import get from 'lodash.get';

export type TFieldValue<T> = T[keyof T];
export type TFieldValidators<T> = Partial<Record<keyof T, (value: TFieldValue<T>) => string>>;
type TFieldState = { touched: boolean; focused: boolean };
export type TFormService<T> = Record<string, TFieldValue<T>>;

type TFormShape<Key, Value> = Record<keyof Key, Value>;
type TFormErrors<T> = TFormShape<T, string>;
type TFormState<T> = TFormShape<T, TFieldState>;

export type THTMLTextElement = HTMLInputElement | HTMLTextAreaElement;
type TChangeEvent = ChangeEvent<THTMLTextElement>;

function defaultStateBuilder(): TFieldState {
  return { focused: false, touched: false };
}

export class FormService<T extends Record<string, TFieldValue<T>>> {
  initialValues: T;
  initialErrors: TFormErrors<T>;
  initialState: TFormState<T>;

  formValues$: BehaviorSubject<T>;
  formErrors$: BehaviorSubject<TFormErrors<T>>;
  formState$: BehaviorSubject<TFormState<T>>;

  formValidators: TFieldValidators<T> | undefined;

  constructor(initialValues: T, validators?: TFieldValidators<T>) {
    this.formValues$ = new BehaviorSubject<T>({ ...initialValues });
    this.formValidators = validators;
    this.initialValues = initialValues;

    this.initialErrors = this.fillShapeWithDefaultValue(initialValues, this.defaultErrorBuilder);
    this.initialState = this.fillShapeWithDefaultValue(initialValues, defaultStateBuilder);

    this.formErrors$ = new BehaviorSubject<TFormErrors<T>>({ ...this.initialErrors });
    this.formState$ = new BehaviorSubject<Record<keyof T, TFieldState>>({ ...this.initialState });
  }

  private defaultErrorBuilder = (fieldName: keyof T, value: TFieldValue<T>): string => {
    return get(this.formValidators, fieldName)?.(value) || '';
  };

  private fillShapeWithDefaultValue<D>(
    initialValues: T,
    defaultValueBuilder: (key: keyof T, value: TFieldValue<T>) => D
  ): TFormShape<T, D> {
    return Object.entries(initialValues)
      .reduce((acc: TFormShape<T, D>, [key, value]: [keyof T, TFieldValue<T>]) => {
        acc[key] = defaultValueBuilder(key, value);

        return acc;
      }, {} as TFormShape<T, D>);
  }

  getValue$<R>(fieldName: keyof T): Observable<R> {
    return this.formValues$.pipe(map((data: T) => {
      return get(data, fieldName) as unknown as R;
    }));
  }

  getError$(fieldName: keyof T): Observable<string> {
    return combineLatest(this.formErrors$, this.formState$)
      .pipe(map(([formErrors, formState]: [TFormErrors<T>, TFormState<T>]): string => {
        const fieldState = get(formState, fieldName);

        return fieldState.touched && !fieldState.focused ? get(formErrors, fieldName) : '';
      }));
  }

  isFullValid$(): Observable<boolean> {
    return this.formErrors$.pipe(map((formErrors: TFormErrors<T>) => {
      return Object.values(formErrors)
        .reduce((acc: boolean, formError: string) => {
          return acc && !formError;
        }, true);
    }));
  }

  setValue(field: keyof T, value: TFieldValue<T>): void {
    const validator = get(this.formValidators, field);

    if (validator) {
      const errors = this.formErrors$.getValue();
      set(errors, field, validator(value));
      this.formErrors$.next(errors);
    }

    const values = this.formValues$.getValue();
    set(values, field, value)
    this.formValues$.next(values);
  }

  setStateOnInputFocus(fieldName: keyof T): void {
    const state = this.formState$.getValue();
    set(state, fieldName, { touched: true, focused: true });
    this.formState$.next(state);
  }

  setStateOnInputBlur(fieldName: keyof T): void {
    const state = this.formState$.getValue();
    set(state, fieldName, { touched: true, focused: false });
    this.formState$.next(state);
  }

  setStateOnInputBlurCallback(fieldName: keyof T): VoidFunction {
    return () => this.setStateOnInputBlur(fieldName);
  }

  setStateOnInputFocusCallback(fieldName: keyof T): VoidFunction {
    return () => this.setStateOnInputFocus(fieldName);
  }

  setValueOnInputChangeCallback(fieldName: keyof T): (event: TChangeEvent) => void {
    return (event: TChangeEvent): void => this.setValue(fieldName, event.target.value as unknown as TFieldValue<T>);
  }

  refreshForm(): void {
    this.formValues$.next({ ...this.initialValues });
    this.formErrors$.next({ ...this.initialErrors });
    this.formState$.next({ ...this.initialState });
  }
}
