import { ErrorModel } from 'api/models/error.model';
import { PersonModel } from 'api/models/person.model';

export enum UserActionType {
  SET_CURRENT_USER = 'SET_CURRENT_USER',
  CURRENT_USER_ERROR = 'CURRENT_USER_ERROR',
}

export type TUserAction = { 
  type: UserActionType; 
  currentUser?: PersonModel;
  error?: ErrorModel;
};

export function setCurrentUser(currentUser: PersonModel) {
  return { type: UserActionType.SET_CURRENT_USER, currentUser };
}

export function userError(error: ErrorModel) {
  return { type: UserActionType.CURRENT_USER_ERROR, error };
}
