import { BaseModel } from 'api/models/base.model';
import { JsonName } from 'tserialize';

export class LanguageModel extends BaseModel {
  @JsonName()
  title!: string;

  @JsonName()
  language!: string;

  constructor(data: object) {
    super(data);

    Object.assign(this, data);
  }
}
