import { JsonArray, JsonName, JsonStruct } from 'tserialize';
import { BaseModel } from './base.model';
import { SiteBlockModel } from './site-block.model';
import { UserPictureModel } from './picture';
import { LanguageModel } from 'api/models/language.model';
import { type TLText } from 'types/text';
import { PagePreviewModel } from './site-page-preview.model';
import { translatableDeserializer } from './deserializers/translatable.deserializer';

const deserializeSiteConfigTranslatable = translatableDeserializer<PagePreviewModel>(PagePreviewModel);

export class SiteConfigTranslatableDataModel extends BaseModel {
  menu: Array<object> = [];
  meta: object = {};
  footer?: Array<object> = [];
  header?: Array<object> = [];
}

export class SiteConfigModel extends BaseModel {
  @JsonName(undefined, val => val === null ? val : Boolean(val))
  published!: boolean | null;

  @JsonName(undefined, val => val === null ? val : Boolean(val))
  is_connected!: boolean | null;

  @JsonName()
  themeOverride?: object;

  @JsonName('preview')
  isPreview?: boolean;

  @JsonName()
  title!: string;

  @JsonName()
  domain!: string;

  /** @deprecated */
  @JsonName()
  description?: string;

  @JsonName()
  analytics?: string;

  @JsonStruct(UserPictureModel, 'logo_picture')
  logoPicture?: UserPictureModel;

  @JsonName()
  footer?: TLText;

  @JsonArray(SiteBlockModel)
  blocks?: Array<SiteBlockModel>;

  @JsonArray(LanguageModel)
  languages!: Array<LanguageModel>;

  @JsonName(undefined, undefined, deserializeSiteConfigTranslatable)
  translatable: Record<string, PagePreviewModel> = {};

  @JsonArray(PagePreviewModel)
  pages: Array<PagePreviewModel> = [];

  constructor(props?: object) {
    super(props);

    Object.assign(this, props);
  }

  updateBlock(blockConfig?: SiteBlockModel, blockIndex?: number): void {
    if (!blockConfig || blockIndex === undefined || !this.blocks) return;

    if (blockIndex < this.blocks.length) {
      this.blocks[blockIndex] = blockConfig;
    } else {
      this.addBlock(blockConfig, blockIndex)
    }

    this.updateBlocks()
  }

  addBlock(blockConfig?: SiteBlockModel, blockIndex?: number) {
    if (!blockConfig) return;

    if (!this.blocks) {
      this.blocks = [];
    }

    if (blockIndex === undefined || blockIndex >= this.blocks.length) {
      this.blocks.push(blockConfig);
      return;
    }

    this.blocks.splice(blockIndex, 0, blockConfig)
    this.updateBlocks()
  }

  removeBlock(blockIndex?: number) {
    if (blockIndex === undefined || !this.blocks) return;

    this.blocks.splice(blockIndex, 1);
    this.updateBlocks()
  }

  moveBlockUp(blockIndex?: number) {
    if (blockIndex === undefined || !this.blocks || blockIndex - 1 < 0) return;

    const block = this.blocks[blockIndex];
    this.blocks[blockIndex] = this.blocks[blockIndex - 1];
    this.blocks[blockIndex - 1] = block;

    this.updateBlocks()
  }

  moveBlockDown(blockIndex?: number) {
    if (blockIndex === undefined || !this.blocks || blockIndex + 1 > this.blocks.length - 1) return;

    const block = this.blocks[blockIndex];
    this.blocks[blockIndex] = this.blocks[blockIndex + 1];
    this.blocks[blockIndex + 1] = block;

    this.updateBlocks()
  }

  setIsPreview(): void {
    this.isPreview = true;
  }

  private updateBlocks() {
    this.blocks = [...this.blocks || []];
  }
}
