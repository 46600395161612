import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import Topbar from './topbar/Topbar';
import { Sidebar } from './sidebar/Sidebar';
import { changeThemeToDark, changeThemeToLight } from 'redux/actions/themeActions';
import { changeMobileSidebarVisibility, changeSidebarVisibility } from 'redux/actions/sidebarActions';
import { TStore } from "redux/store";

export function Layout(): JSX.Element {
  const dispatch = useDispatch();
  const sidebar = useSelector((store: TStore) => store.sidebar);
  const shouldShowSidebar = sidebar.isShownInLayout;

  const layoutClass = classNames({
    layout: true,
    'layout--collapse': sidebar.collapse,
  });

  const sidebarVisibility = () => {
    dispatch(changeSidebarVisibility());
  };

  const mobileSidebarVisibility = () => {
    dispatch(changeMobileSidebarVisibility());
  };

  const changeToDark = () => {
    dispatch(changeThemeToDark());
  };

  const changeToLight = () => {
    dispatch(changeThemeToLight());
  };

  return (
    <div className={layoutClass}>
      <Topbar
        changeMobileSidebarVisibility={mobileSidebarVisibility}
        changeSidebarVisibility={sidebarVisibility}
      />

      {
        shouldShowSidebar ?

        <Sidebar
          sidebar={sidebar}
          changeToDark={changeToDark}
          changeToLight={changeToLight}
          changeMobileSidebarVisibility={mobileSidebarVisibility}
        /> 
        
        : null
      }
    </div>
  );
}
