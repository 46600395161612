export default function createUserAvatar(fullName: string) {
  const firstNameAndLastName = fullName.split(' ');
  
  const initials = `${firstNameAndLastName[0].substr(0, 1)} ${firstNameAndLastName[1].substr(0, 1)}`;

  return (
    <div className="topbar__avatar-img" style={{ 
      backgroundColor: '#0000FF', 
      color: '#ffffff',
      fontSize: '10px', 
      position: 'relative' }}>
      <div style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        color: '#ffffff',
        transform: 'translate(-50%, -50%)',
      }}
      >
        {initials.toUpperCase()}
      </div>
    </div>
  );
};