import { JsonName, JsonStruct } from 'tserialize';
import { BaseModel } from '../base.model';
import { UserPictureModel } from '../picture';
import type { TLText } from 'types/text';

export class CoverBlockModel extends BaseModel {
  @JsonName()
  title?: TLText;

  @JsonName()
  description?: TLText;

  @JsonName('action_title')
  actionTitle?: TLText;

  @JsonName('action_href')
  actionHref?: string;

  @JsonStruct(UserPictureModel, 'background_picture')
  backgroundPicture?: UserPictureModel;

  @JsonStruct(UserPictureModel, 'background_video_poster')
  backgroundVideoPoster?: UserPictureModel;

  @JsonName('background_video_href')
  backgroundVideoHref?: string;

  constructor(data: object) {
    super(data);

    Object.assign(this, data);
  }
}
