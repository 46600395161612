import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'scss/app.scss';
import ThemeProvider from 'wrappers/theme.provider';
import { store } from 'redux/store';
import { ScrollToTopHoc } from 'wrappers/scroll-to-top.hoc';
import 'translations/i18n';
import { PageRouter } from 'routers/page.router';

export default function App() {
  return (
    <Provider store={store}>
      <BrowserRouter basename="/">
        <ScrollToTopHoc>
          <ThemeProvider>
            <PageRouter />
          </ThemeProvider>
        </ScrollToTopHoc>
      </BrowserRouter>
    </Provider>
  );
}
