export function normalizeDomain(domain?: string): string | undefined {
  if (!domain) return;

  return /https:\/\/.+/.test(domain) ? domain : `https://${domain}`
}

export function formatDateTime(backendDateString: string): string {
  const [date, time] = backendDateString.split(' ');

  return `${date.replaceAll('-', '/')} ${time.split(':').slice(0, 2).join(':')}`;
}