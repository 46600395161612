import { PAGE, PAGE_BLOCK_SETTINGS } from 'routers/page.enum';
import { SiteBlockModel } from 'api/models';

type TSettingsPageRouterConfig = {
  currentPage: PAGE_BLOCK_SETTINGS,
  blockIndex: number,
  blockData?: SiteBlockModel
  backAction?: VoidFunction;
  saveAction?: (config: object, initialData?: SiteBlockModel, blockIndex?: number) => VoidFunction;
};

export enum RouteActionType {
  GO_TO_PAGE = 'GO_TO_PAGE',
  REDIRECT_TO_PAGE = 'REDIRECT_TO_PAGE',
  GO_TO_PAGE_BLOCK_SETTINGS = 'GO_TO_PAGE_BLOCK_SETTINGS'
}

export type TRouteAction = {
  type: RouteActionType,
  currentPage: PAGE | PAGE_BLOCK_SETTINGS,
  blockIndex?: number,
  blockData?: SiteBlockModel
  backAction?: VoidFunction;
  saveAction?: (config: object, initialData?: SiteBlockModel, blockIndex?: number) => VoidFunction;
};

export function goToPage(currentPage: PAGE) {
  return { type: RouteActionType.GO_TO_PAGE, currentPage };
}

export function redirectToPage(currentPage: PAGE) {
  return { type: RouteActionType.REDIRECT_TO_PAGE, currentPage };
}

export function goToPageBlockSettings({
  currentPage,
  blockIndex,
  blockData,
  backAction,
  saveAction
}: TSettingsPageRouterConfig) {
  return {
    type: RouteActionType.GO_TO_PAGE_BLOCK_SETTINGS,
    currentPage,
    blockIndex,
    blockData,
    backAction,
    saveAction
  };
}
