import { deserialize, serialize } from "tserialize";

export type TBaseModelChild<T> = new (props?: object) => T;

export class BaseModel {
  constructor(data?: object) {
    data && Object.assign(this, data);
  }

  static fromServer<T extends BaseModel>(this: TBaseModelChild<T>, data: object): T {
    if (!this) throw Error('fromServer context is missing');
    if (!data) return data;

    return deserialize(data, this);
  }

  toServer(): object {
    return serialize(this);
  }
}