export enum ChangeDirectionActionType {
  CHANGE_DIRECTION_TO_LTR = 'CHANGE_DIRECTION_TO_LTR',
  CHANGE_DIRECTION_TO_RTL = 'CHANGE_DIRECTION_TO_RTL'
};

export type TChangeDirectionAction = {
  type: ChangeDirectionActionType,
};

export function changeDirectionToLTR() {
  return {
    type: ChangeDirectionActionType.CHANGE_DIRECTION_TO_LTR,
  };
};

export function changeDirectionToRTL() {
  return {
    type: ChangeDirectionActionType.CHANGE_DIRECTION_TO_RTL,
  };
};
