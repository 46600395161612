import { JsonName } from "tserialize";
import { BaseModel } from "./base.model";

export class PageMetaModel extends BaseModel {
  @JsonName()
  meta_title?: string;

  @JsonName()
  meta_description?: string;

  @JsonName()
  meta_keywords?: string;

  @JsonName()
  meta_image?: string;
}