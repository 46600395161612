import ReactDOM from "react-dom";
import { store } from "redux/store";
import { Provider } from "react-redux";
import React from "react";

function createContainer(): HTMLDivElement {
  const div = document.createElement('div');
  div.className = 'portal';
  document.body.appendChild(div);

  return div;
}

function removeContainer(container?: Element): void {
  if (!container) return;

  document.body.removeChild(container);
}

function renderMemoizedPortal(element: JSX.Element, memoizedContainers: Array<HTMLDivElement>): void {
  /*
    Порталы так как рендерятся не внутри приложения в DOM-дереве, а в портале, то на них никакие обертки не работают,
    так что надо обязательно оборачивать тут в те провайдеры, инфу из которых хочешь, чтобы содержимое порталов получало
   */
  const wrappedElement = <Provider store={store}>{element}</Provider>;

  const div = createContainer();
  memoizedContainers.push(div);

  ReactDOM.render(wrappedElement, div);
}

function closeMemoizedPortal(element?: Element | null): void {
  if (!element) return;

  removeContainer(element)
}

function closeAllMemoizedPortals(memoizedContainers: Array<HTMLDivElement>): void {
  memoizedContainers.forEach((container: HTMLDivElement) => {
    removeContainer(container);
  });
}

class UiKitService {
  private openedModalContainers: Array<HTMLDivElement> = [];
  private openedAlertContainers: Array<HTMLDivElement> = [];

  openAlert(alert: JSX.Element): void {
    renderMemoizedPortal(alert, this.openedAlertContainers);
  }

  closeAlert(): void {
    closeMemoizedPortal(this.openedAlertContainers.pop());
  }

  closeAllAlerts(): void {
    closeAllMemoizedPortals(this.openedAlertContainers);
    this.openedAlertContainers = [];
  }

  openModal(modal: JSX.Element): void {
    renderMemoizedPortal(modal, this.openedModalContainers);
  }

  closeModal(): void {
    // Все это нужно, чтобы эмулировать закрытие модалки, так как делаем это извне, что не предполагается компонентом
    const portal = this.openedModalContainers[this.openedModalContainers.length - 1];
    if (!portal) return;

    const modalWrapper = portal.nextElementSibling;
    const modal = modalWrapper?.querySelector('.modal');
    const modalBackdrop = modalWrapper?.querySelector('.modal-backdrop');

    document.body.classList.remove('modal-open');
    [modal, modalBackdrop].map(element => element?.classList.remove('show'));

    closeMemoizedPortal(this.openedModalContainers.pop());

    // Ждем завершения анимации и удаляем контейнер модалки самого компонента, так как он рендерится в отдельном портале
    setTimeout(() => closeMemoizedPortal(modalWrapper), 200);
  }

  closeAllModals(): void {
    closeAllMemoizedPortals(this.openedModalContainers);
    this.openedModalContainers = [];
  }
}

export const uiKitService = new UiKitService();