export enum SiteBlock {
  COVER = 'COVER',
  SHOWCASE = 'SHOWCASE',
  ROUTE = 'ROUTE',
  MILESTONE = 'MILESTONE',
  TEXT = 'TEXT',
  HTML = 'HTML',
  ANCHOR = 'ANCHOR',
  GALLERY = 'GALLERY',
  EMPTY_SPACE = 'EMPTY_SPACE'
}
