import { SidebarActionType, TSidebarAction } from 'redux/actions/sidebarActions';

export type TSidebarState = { isShownInLayout: boolean, show: boolean, collapse: boolean };

const initialState: TSidebarState = {
  isShownInLayout: false,
  show: false,
  collapse: true,
};

export const sidebarReducer = (state: TSidebarState = initialState, action: TSidebarAction): TSidebarState => {
  switch (action.type) {
    case SidebarActionType.CHANGE_SIDEBAR_VISIBILITY:
      return { ...state, collapse: !state.collapse };
    case SidebarActionType.CHANGE_MOBILE_SIDEBAR_VISIBILITY:
      return { ...state, show: !state.show };
    case SidebarActionType.SHOW_SIDEBAR:
      return { ...state, isShownInLayout: true, };
    default:
      return state;
  }
};

export default sidebarReducer;