import { JsonName } from 'tserialize';
import { BaseModel } from '../base.model';

export class PlaneHtmlBlockModel extends BaseModel {
  @JsonName('class_name')
  className!: string;

  @JsonName('raw_html')
  rawHtml?: string;

  constructor(data: object) {
    super(data);

    Object.assign(this, data);
  }
}