import { JsonArray, JsonName } from "tserialize";
import { BaseModel } from "./base.model";
import { MilestoneModel } from "./milestone.model";

export class BusinessModel extends BaseModel {
  @JsonName()
  id!: number;

  @JsonName()
  title!: string;

  @JsonName()
  description?: string;

  @JsonName('price_adult')
  priceAdult!: number;

  @JsonName('price_child')
  priceChild!: number;

  @JsonArray(MilestoneModel)
  milestones!: Array<MilestoneModel>;
}