import ApiService from "api/api.service";
import { ErrorModel, PageModel, PagePreviewModel } from "api/models"
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { TStore } from "redux/store";

export enum PageActionType {
  SET_PAGES = 'SET_PAGES',
  CHANGE_SITE_PAGE = 'CHANGE_SITE_PAGE',
  CLOSE_SITE_PAGE = 'CLOSE_SITE_PAGE',
  CLEAR_PAGES = 'CLEAR_PAGES',
  PAGES_START_LOADING = 'PAGES_START_LOADING',
  PAGES_STOP_LOADING = 'PAGES_STOP_LOADING',
  SET_ERROR = 'SET_ERROR',
  SWITCH_TO_LANGUAGE = 'SWITCH_TO_LANGUAGE',
}

type TPageActionChangePage = {
  type: PageActionType.CHANGE_SITE_PAGE,
  pages?: Array<PagePreviewModel>,
  currentPage: PageModel;
  error?: ErrorModel;
}
type TPageActionClosePage = {
  type: PageActionType.CLOSE_SITE_PAGE,
  pages?: [],
  currentPage?: null;
}

type TPageActionSetPages = {
  type: PageActionType.SET_PAGES,
  pages: Array<PagePreviewModel>,
  currentPage?: PageModel;
};

type TPageActionClearPages = {
  type: PageActionType.CLEAR_PAGES
};

type TPageActionStartLoading = {
  type: PageActionType.PAGES_START_LOADING
}

type TPageActionStopLoading = {
  type: PageActionType.PAGES_STOP_LOADING
}

type TPageActionSetError = {
  type: PageActionType.SET_ERROR,
  error: ErrorModel,
}

type TPageActionSwitchToLanguage = {
  type: PageActionType.SWITCH_TO_LANGUAGE,
  language: string
}

export type TPageAction =
  | TPageActionChangePage
  | TPageActionClosePage
  | TPageActionSetPages
  | TPageActionClearPages
  | TPageActionStartLoading
  | TPageActionStopLoading
  | TPageActionSetError
  | TPageActionSwitchToLanguage;

export function changePage(currentPage: PageModel): ThunkAction<void, TStore, unknown, Action> {
  return async (dispath, getState) => {
    dispath({ type: PageActionType.CHANGE_SITE_PAGE, currentPage });
    let defaultLanguage = getState().page.currentLanguage;
    if (!defaultLanguage || !currentPage.translatable?.[defaultLanguage]){
      defaultLanguage = Object.keys(currentPage.translatable || {})[0];
    }

    if (defaultLanguage) {
      dispath(switchToLanguage(defaultLanguage));
    }
  };
}

export function switchToLanguage(language: string): TPageActionSwitchToLanguage {
  return { type: PageActionType.SWITCH_TO_LANGUAGE, language };
}

export function _loadPageDetails(pageUrl: string, domain: string, preview?: boolean): ThunkAction<void, TStore, unknown, Action> {
  return async dispatch => {
    const page = await ApiService.getPageDetail(domain, pageUrl, preview);
    dispatch(changePage(page)); 
  }
}

export function loadPageDetails(pageUrl: string, domain: string, preview?: boolean): ThunkAction<void, TStore, unknown, Action> {
  return async (dispatch, getState) => {
    dispatch(startLoading());
    try {
      dispatch(_loadPageDetails(pageUrl, domain, preview));
    } catch (error) {
      dispatch(setError(error as ErrorModel));
    } finally {
      dispatch(stopLoading());
    }
  }
}

export function _loadPagesList(busindssId: number, siteId: number): ThunkAction<void, TStore, unknown, Action> {
  return async dispatch => {
    const siteDetail = await ApiService.getSiteDetail(busindssId, siteId);
    dispatch(setPages(siteDetail.config.pages || []));
  }
}

export function loadPagesList(busindssId: number, siteId: number): ThunkAction<void, TStore, unknown, Action> {
  return async dispatch => {
    dispatch(startLoading());
    try {
      dispatch(_loadPagesList(busindssId, siteId));
    } catch (error) {
      dispatch(setError(error as ErrorModel));
    } finally {
      dispatch(stopLoading());
    }
  }
}

export function loadPagesAndSetDefault(busindssId: number, siteId: number, defaultUrl: string = '/'): ThunkAction<void, TStore, unknown, Action> {
  return async (dispath, getState) => {
    dispath(startLoading());
    try {
      let currentSite = getState().site.currentSite;
      if (!currentSite?.businessId || !currentSite.id || !currentSite.config) {
        currentSite = await ApiService.getSiteDetail(busindssId, siteId);
      }
      dispath(_loadPagesList(busindssId, siteId));
      try {
        dispath(_loadPageDetails(defaultUrl, currentSite.domain, currentSite.config.isPreview));
      } catch (error) {}
    } catch (error) {
      dispath(setError(error as ErrorModel));
    } finally {
      dispath(stopLoading());
    }
  };
}

export function closePage(): TPageActionClosePage {
  return { type: PageActionType.CLOSE_SITE_PAGE };
}

export function setPages(pages: Array<PagePreviewModel>): TPageActionSetPages {
  return { type: PageActionType.SET_PAGES, pages };
}

export function clearPages(): TPageActionClearPages {
  return { type: PageActionType.CLEAR_PAGES };
}

export function startLoading(): TPageActionStartLoading {
  return { type: PageActionType.PAGES_START_LOADING };
}

export function stopLoading(): TPageActionStopLoading {
  return { type: PageActionType.PAGES_STOP_LOADING };
}

export function setError(error: ErrorModel): TPageActionSetError {
  return { type: PageActionType.SET_ERROR, error };
}