import { BaseModel } from 'api/models/base.model';
import { JsonName } from 'tserialize';

export class UserPictureModel extends BaseModel {
  @JsonName('file_name')
  fileName!: string;

  @JsonName('file_code')
  fileCode!: string;

  constructor(data: object) {
    super(data);

    Object.assign(this, data);
  }
}