import { CSSProperties, useMemo } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { TStore } from 'redux/store';

type TElement = 'span' | 'div' | 'p' | 'h1' | 'h2' | 'h3';

type TWeightString = '300' | '400' | '500' | '700' | '800';
type TWeightNumber = 300 | 400 | 500 | 700 | 800
type TWeight = TWeightString | TWeightNumber;

type TClickFunction = VoidFunction | ((event: any) => void);
type TChild = string | JSX.Element | undefined;

export type TTextProps = {
  children: TChild | Array<TChild>;
  className?: string;
  size?: string | number;
  weight?: TWeight;
  color?: string;
  element?: TElement;
  onClick?: TClickFunction;
  dir?: 'rtl' | 'ltr';
  center?: boolean;
};

type TContentProps = {
  className: string;
  children: TChild | Array<TChild>;
  style: CSSProperties;
  onClick?: TClickFunction;
  dir?: 'rtl' | 'ltr'
};

export function Text({ children, className, size, weight, color, element, onClick, dir, center }: TTextProps): JSX.Element {
  const direction = useSelector((store: TStore) => store.rtl.direction);
  const directionProp = useMemo(() => dir || direction, [dir, direction]);

  const style = useMemo((): CSSProperties => {
    return {
      fontSize: `${size}px`,
      fontWeight: weight,
      color: color,
      textAlign: center ? 'center' : (directionProp === 'rtl' ? 'right' : 'left')
    }
  }, [size, weight, color]);

  const classNameProp = useMemo(() => {
    return classNames('text', {
      [className || '']: className
    });
  }, [className])

  const TextElement = useMemo((): (props: TContentProps) => JSX.Element => {
    switch (element) {
      case 'span':
        return (props: TContentProps) => <span {...props}/>;
      case 'div':
        return (props: TContentProps) => <div {...props}/>;
      case 'p':
        return (props: TContentProps) => <p {...props}/>;
      case 'h1':
        return (props: TContentProps) => <h1 {...props}/>;
      case 'h2':
        return (props: TContentProps) => <h2 {...props}/>;
      case 'h3':
        return (props: TContentProps) => <h3 {...props}/>;

      default:
        return (props: TContentProps) => <p {...props}/>;
    }
  }, [element]);

  return <TextElement dir={directionProp} className={classNameProp} style={style} onClick={onClick}>{children}</TextElement>;
}
