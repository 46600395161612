import { JsonName, JsonStruct } from 'tserialize';
import { PersonModel } from './person.model';
import { BaseModel } from "./base.model";

export class LoginResponse extends BaseModel {
  @JsonName()
  token!: string;

  @JsonStruct(PersonModel)
  person!: PersonModel
}