import { useDispatch } from 'react-redux';
import { logout } from 'redux/actions/auth.action';
import { clearCurrentSite } from 'redux/actions/site.action';
import { clearCurrentBusiness } from 'redux/actions/business.action';
import { useTranslation } from 'react-i18next';
import { goToPage } from 'redux/actions/route.action';
import { PAGE } from 'routers/page.enum';
import { uiKitService } from 'ui-kit/ui-kit.service';
import { LoginModal } from 'pages/login';

export function LogOut(): JSX.Element {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  return (
    <button className="topbar__link" onClick={() => {
      dispatch(goToPage(PAGE.LOGIN));
      dispatch(clearCurrentSite());
      dispatch(clearCurrentBusiness());
      dispatch(logout());
      uiKitService.openModal(<LoginModal/>);
    }}>
      <span className={`topbar__link-icon lnr lnr-exit`} />
      <p className="topbar__link-title">{t("buttons.logOut")}</p>
    </button>
  )
}
