import React, { useMemo } from 'react';
import classNames from "classnames";

type TButtonProps = {
  isActive?: boolean;
  onClick: VoidFunction;
  children: JSX.Element;
}

export function ControlButton({ children, isActive, onClick }: TButtonProps): JSX.Element {
  const className = useMemo(() => {
    return classNames('form__form-group-button', { active: isActive });
  }, [isActive]);

  return children && <button className={className} onClick={onClick}>{children}</button>;
}