import React from 'react';
import createUserAvatar from './createUserAvatar';
import { PersonModel } from 'api/models/person.model'

export default function avatarRender (currentUser: PersonModel) {
  const { fullName, avatar, facebookUserId } = currentUser || {};
  const { thumbnailUrl: userAvatar } = avatar || {};

  if (userAvatar) {
    return (
      <img
          className="topbar__avatar-img"
          src={`${userAvatar}`}
          alt="avatar"
      />
    );
  } else if (facebookUserId) {
    return (
      <img
      className="topbar__avatar-img"
      src={`https://graph.facebook.com/${facebookUserId}/picture`}
      alt="avatar"
      />
    );
  } else if(fullName) { 
    return createUserAvatar(fullName)
  } else return null;
};