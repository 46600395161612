import { BaseModel, ErrorModel } from './models';
import { store } from 'redux/store';

enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE'
}

export default class TransportService {
  static ENDPOINT = 'https://api.test.pinsteps.com';

  static buildDestination(method: string) {
    return `${TransportService.ENDPOINT}/${method}`;
  }

  static makeFetch(method: string, requestMethod: RequestMethod, payload?: BaseModel) {
    const headers = TransportService.buildHeaders(!!payload);
    const request = {
      headers: {
        Accept: 'application/json',
        ...headers
      },
      method: requestMethod,
      body: payload ? JSON.stringify(payload.toServer()) : undefined
    };
   
    return fetch(TransportService.buildDestination(method), request)
      .then(TransportService.catchError)
      .then(response => response.json())
      .catch(TransportService.handleError);
  }

  static uploadFile(method: string, payload?: FormData) {
    const request = {
      headers: TransportService.buildAuthorizationHeader(),
      method: RequestMethod.POST,
      body: payload
    };

    return fetch(TransportService.buildDestination(method), request)
      .then(TransportService.catchError)
      .then(response => response.json())
      .catch(TransportService.handleError);
  }
  
  static get(method: string) {
    return TransportService.makeFetch(method, RequestMethod.GET);
  }

  static post(method: string, payload?: BaseModel) {
    return TransportService.makeFetch(method, RequestMethod.POST, payload);
  }

  static put(method: string, payload?: BaseModel) {
    return TransportService.makeFetch(method, RequestMethod.PUT, payload);
  }

  static delete(method: string, payload?: BaseModel) {
    return TransportService.makeFetch(method, RequestMethod.DELETE, payload);
  }

  private static catchError(response: any) {
    if (response.status !== 200) {
      throw response;
    }

    return response
  }

  private static async handleError(error: any) {
    let jsonError;
    try{
      jsonError = await error.json();
    }
    catch (err) {
      jsonError = { message: error.toString(), result: false };
    }

    throw new ErrorModel(jsonError);
  }

  private static getToken() {
    return store.getState().auth.token;
  }

  private static buildHeaders(hasBody: boolean): Record<string, string> {
    const authorisationHeader = TransportService.buildAuthorizationHeader();
    const contentHeader: Record<string, string> = hasBody ? { 'Content-Type': 'application/json; charset=UTF-8' } : { };

    return { ...authorisationHeader, ...contentHeader };
  }

  private static buildAuthorizationHeader(): Record<string, string> {
    const token = TransportService.getToken();

    return token ? { Authorization: `Bearer ${token}` } : { };
  }
}
