import { JsonName } from "tserialize";
import { BaseModel } from "./base.model";

type TErrorModel = { message: string; payload: object; result: boolean };

export class ErrorModel extends BaseModel {
  @JsonName()
  message!: string;

  @JsonName()
  payload?: object;

  @JsonName()
  result?: boolean;

  constructor({ message, payload, result }: TErrorModel) {
    super({ message, payload, result });

    this.message = message;
    this.payload = payload;
    this.result = result;
  }
}
