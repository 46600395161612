import { JsonName } from 'tserialize';
import { BaseModel } from "./base.model";

export class AvatarModel extends BaseModel {
  @JsonName()
  crop?: string;

  @JsonName('crop_url')
  cropUrl?: string;

  @JsonName()
  id?: number;

  @JsonName('crop_url')
  original?: string;

  @JsonName('original_url')
  originalUrl?: string;

  @JsonName()
  thumbnail?: string;

  @JsonName('thumbnail_url')
  thumbnailUrl?: string;
}