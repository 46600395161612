import { 
  combineReducers, 
  createStore, 
  applyMiddleware, 
  compose,
} from 'redux';
import thunk from 'redux-thunk';
import {
  authReducer,
  businessReducer,
  userReducer,
  themeReducer,
  rtlReducer,
  customizerReducer,
  sidebarReducer,
  roundBordersReducer,
  blocksShadowsReducer,
  routeReducer,
  siteReducer,
  pageReducer,
} from 'redux/reducers';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: Function;
  }
}

const reducer = combineReducers({
  auth: authReducer,
  business: businessReducer,
  user: userReducer,
  site: siteReducer,
  page: pageReducer,
  route: routeReducer,
  theme: themeReducer,
  rtl: rtlReducer,
  roundBorders: roundBordersReducer,
  blocksShadows: blocksShadowsReducer,
  customizer: customizerReducer,
  sidebar: sidebarReducer,
});

const middlewares = [thunk];
const enhancers = [];

if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
  
    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
}

const composedEnhancers = compose(
    applyMiddleware(...middlewares),
    ...enhancers,
);

export type TStore = ReturnType<typeof reducer>;
export const store = createStore(reducer, composedEnhancers);