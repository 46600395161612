import { JsonName } from "tserialize";
import { BaseModel } from "./base.model";
import { SiteConfigModel } from './site-config.model';

function deserializeConfig(data: string) {
  return SiteConfigModel.fromServer(JSON.parse(data));
}

export class SiteModel extends BaseModel {
  @JsonName()
  id!: number;

  @JsonName()
  title!: string;

  /**
   * @deprecated let's use config.domain
   */
  @JsonName()
  domain!: string;

  @JsonName('business_id')
  businessId!: number;

  @JsonName()
  created!: string;

  @JsonName()
  updated!: string;

  @JsonName('is_connected')
  isConnected?: boolean;

  @JsonName('config', undefined, deserializeConfig)
  config!: SiteConfigModel;

  @JsonName('config_preview', undefined, deserializeConfig)
  config_preview!: SiteConfigModel;

  constructor(data?: object) {
    super(data);

    Object.assign(this, data);
  }
}