import { BaseModel } from 'api/models/base.model';
import { JsonName } from 'tserialize';

export class EmptySpaceBlockModel extends BaseModel {
  @JsonName('empty_space')
  emptySpace!: string;

  constructor(data: object) {
    super(data);

    Object.assign(this, data);
  }
}
