import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageLayout from 'layouts/page-layout';
import { TStore } from 'redux/store';
import { PAGE, PAGE_BLOCK_SETTINGS } from 'routers/page.enum';
import { goToPage, redirectToPage } from "redux/actions/route.action";
import { clearCurrentBusiness } from "redux/actions/business.action";
import { clearSiteAndPages } from "redux/actions/site.action";
import { uiKitService } from 'ui-kit/ui-kit.service';
import { LoginModal } from 'pages/login';

const BusinessChoicePage = React.lazy(() => import('pages/business-choice'));
const SiteChoicePage = React.lazy(() => import('pages/site-choice'));
const ConstructorPage = React.lazy(() => import('pages/constructor/constructor.page'));
const CreateSitePage = React.lazy(() => import('pages/create-site'));
const SiteSettingsPage = React.lazy(() => import('pages/site-settings'));
const CustomStylesSettingsPage = React.lazy(() => import('pages/custom-styles-settings'));

const CoverSettingsPage = React.lazy(() => import('pages/block-settings-pages/cover-settings'));
const ShowcaseSettingsPage = React.lazy(() => import('pages/block-settings-pages/showcase-settings'));
const RouteSettingsPage = React.lazy(() => import('pages/block-settings-pages/route-settings'));
const MilestoneSettingsPage = React.lazy(() => import('pages/block-settings-pages/milestone-settings'));
const TextBlockSettingsPage = React.lazy(() => import('pages/block-settings-pages/text-block-settings'));
const HTMLBlockSettingsPage = React.lazy(() => import('pages/block-settings-pages/html-block-settings'));
const GalleryBlockSettingsPage = React.lazy(() => import('pages/block-settings-pages/gallery-block-settings'));
const MenuAnchorBlockPage = React.lazy(() => import('pages/block-settings-pages/menu-anchor-block-settings'));
const EmptySpaceBlockSettingsPage = React.lazy(() => import('pages/block-settings-pages/empty-space-settings'));

export function PageRouter() {
  const isLoggedIn = useSelector((store: TStore) => store.auth.isLoggedIn);
  const { currentPage, backAction, saveAction } = useSelector((store: TStore) => store.route);
  const currentBusiness = useSelector((store: TStore) => store.business.currentBusiness);
  const currentSite = useSelector((store: TStore) => store.site.currentSite);
  const dispatch = useDispatch();

  useEffect(() => {
    window.onpopstate = (event) => {
      if (event.state?.currentPage) {
        event.preventDefault();
        event.stopPropagation();
        dispatch(redirectToPage(event.state.currentPage));
      } else {
        window.history.back();
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isLoggedIn) {
      uiKitService.openModal(<LoginModal/>)
    } else if(!currentBusiness) {
       dispatch(goToPage(PAGE.BUSINESS_CHOICE));
    } else if (!currentSite) {
      dispatch(goToPage(PAGE.SITE_CHOICE));
    }
  }, []); // cпециально оставили пустыми зависимости, так как надо только, чтобы в момент загрузки отработало

  const page = useMemo((): JSX.Element | undefined => {
    switch (currentPage) {
      case PAGE.BUSINESS_CHOICE:
        dispatch(clearCurrentBusiness());
        dispatch(clearSiteAndPages());
        return <BusinessChoicePage/>;
      case PAGE.SITE_CREATION:
        return <CreateSitePage/>;
      case PAGE.SITE_CHOICE:
        dispatch(clearSiteAndPages());
        return <SiteChoicePage/>;
      case PAGE.CONSTRUCTOR:
        return <ConstructorPage/>;
      case PAGE.SITE_SETTINGS:
        return <SiteSettingsPage/>;
      case PAGE.CUSTOM_STYLES_SETTINGS:
        return <CustomStylesSettingsPage/>;

      case PAGE_BLOCK_SETTINGS.SITE_COVER_SETTINGS:
        return <CoverSettingsPage backAction={backAction || (() => void 0)} saveAction={saveAction || (() => () => void 0)}/>;
      case PAGE_BLOCK_SETTINGS.SITE_SHOWCASE_SETTINGS:
        return <ShowcaseSettingsPage backAction={backAction || (() => void 0)} saveAction={saveAction || (() => () => void 0)}/>;
      case PAGE_BLOCK_SETTINGS.SITE_ROUTE_SETTINGS:
        return <RouteSettingsPage backAction={backAction || (() => void 0)} saveAction={saveAction || (() => () => void 0)}/>;
      case PAGE_BLOCK_SETTINGS.SITE_MILESTONE_SETTINGS:
        return <MilestoneSettingsPage backAction={backAction || (() => void 0)} saveAction={saveAction || (() => () => void 0)}/>;
      case PAGE_BLOCK_SETTINGS.SITE_TEXT_BLOCK_SETTINGS:
        return <TextBlockSettingsPage backAction={backAction || (() => void 0)} saveAction={saveAction || (() => () => void 0)}/>;
      case PAGE_BLOCK_SETTINGS.SITE_HTML_BLOCK_SETTINGS:
        return <HTMLBlockSettingsPage backAction={backAction || (() => void 0)} saveAction={saveAction || (() => () => void 0)}/>;
      case PAGE_BLOCK_SETTINGS.SITE_MENU_ANCHOR_BLOCK_SETTINGS:
        return <MenuAnchorBlockPage backAction={backAction || (() => void 0)} saveAction={saveAction || (() => () => void 0)}/>;
      case PAGE_BLOCK_SETTINGS.SITE_GALLERY_BLOCK_SETTINGS:
        return <GalleryBlockSettingsPage/>;
      case PAGE_BLOCK_SETTINGS.SITE_EMPTY_SPACE_BLOCK_SETTINGS:
        return <EmptySpaceBlockSettingsPage backAction={backAction || (() => void 0)} saveAction={saveAction || (() => () => void 0)}/>
    }
  }, [currentPage, dispatch]);

  return <PageLayout component={page} />
}
