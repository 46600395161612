import { uiKitService } from "ui-kit/ui-kit.service";
import { Alert } from "ui-kit/alert";
import React from "react";

export function openErrorAlert(message: string): void {
  uiKitService.openAlert(<Alert type="danger" message={message}/>);
}

export function openSuccessAlert(message: string): void {
  uiKitService.openAlert(<Alert type="success" message={message}/>);
}