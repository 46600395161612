import { BusinessModel, ErrorModel } from "api/models";

export enum BusinessActionType {
  SET_BUSINESSES = 'SET_BUSINESSES',
  BUSINESSES_ERROR = 'BUSINESSES_ERROR',
  CHOOSE_BUSINESS = 'CHOOSE_BUSINESS',
  CLEAR_CURRENT_BUSINESS = 'CLEAR_CURRENT_BUSINESS',
}

export type TBusinessAction = { 
  type: BusinessActionType; 
  businesses?: Array<BusinessModel>; 
  currentBusiness?: BusinessModel; 
  error?: ErrorModel 
};

export function setBusinesses(businesses: Array<BusinessModel>) {
  return { type: BusinessActionType.SET_BUSINESSES, businesses };
}

export function businessesError(error: ErrorModel) {
  return { type: BusinessActionType.BUSINESSES_ERROR, error };
}

export function chooseBusiness(currentBusiness: BusinessModel) {
  return { type: BusinessActionType.CHOOSE_BUSINESS, currentBusiness };
}

export function clearCurrentBusiness() {
  return { type: BusinessActionType.CLEAR_CURRENT_BUSINESS };
}
