import { JsonName } from 'tserialize';
import { BaseModel } from '../base.model';

export class RouteBlockModel extends BaseModel {
  @JsonName()
  id!: number;

  constructor(data: object) {
    super(data);

    Object.assign(this, data);
  }
}