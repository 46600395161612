import { useEffect, useState } from 'react';
import { Alert as RbAlert } from 'reactstrap';
import './alert.scss';
import { uiKitService } from "ui-kit/ui-kit.service";

type TAlertProps = {
  type?: 'info' | 'success' | 'warning' | 'danger';
  message?: string;
  className?: string
};

export function Alert({ type, className, message}: TAlertProps): JSX.Element {
  const [animation, setAnimation] = useState<'slideDown' | 'slideUp'>('slideDown');

  useEffect(() => {
    setTimeout(() => setAnimation('slideUp'), 3000);
    setTimeout(() => uiKitService.closeAlert(), 3500);
  }, []);

  return (
    <div className={`alert__container ${animation}`}>
      <RbAlert color={type} className={className}>
        <div className="alert__content">{message}</div>
      </RbAlert>
    </div>
  );
}

