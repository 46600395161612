import React from 'react';
import './modal.scss';
import { Modal as RbModal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { uiKitService } from "ui-kit/ui-kit.service";
import { TText } from "utils/typeguards/is-text-string";
import { Offset } from "ui-kit/offset";

type TModalProps = {
  header?: TText;
  subheader?: TText;
  footer?: JSX.Element | Array<JSX.Element>;
  children: JSX.Element | Array<JSX.Element>;
  backAction?: VoidFunction | false | null;
  isCentered?: boolean;
  direction?: string;
};

export function Modal({ header, subheader, children, footer, backAction, isCentered, direction }: TModalProps): JSX.Element {
  return (
    <RbModal
      unmountOnClose
      scrollable
      isOpen={true}
      zIndex={10000}
      centered={isCentered}
      toggle={() => uiKitService.closeModal()}
      modalClassName={direction && `${direction}-support`}
    >
      <ModalHeader tag="h3">
        {backAction && (
          <button
            className="lnr lnr-arrow-left modal__back-btn"
            aria-label="modal__back-btn"
            type="button"
            onClick={backAction}
          />
        )}
        <button
          className="lnr lnr-cross modal__close-btn"
          aria-label="modal__close-btn"
          type="button"
          onClick={() => uiKitService.closeModal()}
        />
        {header}
      </ModalHeader>
      <ModalBody>
        {subheader && <>
          <h3 className="subhead">{subheader}</h3>
          <Offset offset={24}/>
        </>}
        {children}
      </ModalBody>
      {footer && (
        <ModalFooter>
          {footer}
        </ModalFooter>
      )}
    </RbModal>
  );
}
