import React, { PropsWithChildren, SyntheticEvent, useCallback } from 'react';
import './link.scss';
import classNames from "classnames";

type TLinkProps = PropsWithChildren<{
  icon?: JSX.Element;
  color?: 'blue' | 'black' | 'red'
  href?: string;
  onClick?: VoidFunction;
}>

export function Link({ icon, color = 'blue', href, onClick, children }: TLinkProps): JSX.Element {
  const className = classNames('link', {
    [`${color}`]: color
  });

  const onClickFunction = useCallback((event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    href && window.open(href, '_blank', )?.focus();
    onClick && onClick();
  }, [href, onClick]);

  return (
    <button className={className} onClick={onClickFunction}>
      {icon}
      {children}
    </button>
  );
}