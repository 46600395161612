import { useDispatch } from 'react-redux';
import { goToPage } from "redux/actions/route.action";
import { PAGE } from "routers/page.enum";
import { useTranslation } from 'react-i18next';

export function GoToBusinessesList(): JSX.Element {
  const dispatch = useDispatch();

  const { t } = useTranslation('common');

  return (
    <button className="topbar__link" onClick={() => dispatch(goToPage(PAGE.BUSINESS_CHOICE))}>
      <span className={`topbar__link-icon lnr lnr-briefcase`} />
      <p className="topbar__link-title">{t("buttons.chooseBusiness")}</p>
    </button>
  )
}
