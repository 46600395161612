import TransportService from './transport.service';
import {
  BusinessModel,
  LoginRequest,
  LoginResponse,
  SiteModel,
  PersonModel,
  SiteConfigModel,
  CustomSiteStylesRequest,
  PublishSiteResponse,
  PictureModel,
  TRegisterUserRequest,
  RegisterUserRequest,
  PageModel,
} from './models';

export default class ApiService {
  static login(email: string, password: string): Promise<LoginResponse> {
    const request = new LoginRequest(email, password);

    return TransportService.post('auth/login-by-email', request)
      .then(response => LoginResponse.fromServer(response));
  }

  static registerUser(_request: TRegisterUserRequest): Promise<PersonModel> {
    const request = new RegisterUserRequest(_request);

    return TransportService.post('auth/register', request)
      .then((data: object) => PersonModel.fromServer(data));
  }

  static getUserInfo(personID: any): Promise<PersonModel> {
    return TransportService.get(`person/${personID}`)
      .then((response: PersonModel) => PersonModel.fromServer(response));
  }

  static getBusinesses(): Promise<Array<BusinessModel>> {
    return TransportService.get('business')
      .then((response: Array<object>) => response.map(business => BusinessModel.fromServer(business)));
  }

  static getSites(businessId: number): Promise<Array<SiteModel>> {
    return TransportService.get(`business/${businessId}/sites?extra=config_preview`)
      .then((response: Array<object>) => response.map(site => SiteModel.fromServer(site)));
  }

  static getSiteDetail(businessId: number, siteId: number): Promise<SiteModel> {
    return TransportService.get(`business/${businessId}/sites/${siteId}`)
      .then((response: object) => SiteModel.fromServer(response));
  }

  static getPageDetail(domain: string, url: string, preview?: boolean): Promise<PageModel> {
    return TransportService.get(`business/sites/pages/config?domain=${domain}&slug=${url}${preview ? '&preview=1' : ''}`)
      .then(data => PageModel.fromServer(data));
  }

  static createSite(businessId: number, title: string, domain: string): Promise<SiteModel> {
    const request = new SiteModel({ title, domain });

    return TransportService.post(`business/${businessId}/sites?extra=config`, request)
      .then((response) => {
        return SiteModel.fromServer({ ...response, config: JSON.stringify({ title, domain }) });
      });
  }

  static updateSiteConfig(businessId: number, siteId: number, config: SiteConfigModel): Promise<SiteConfigModel> {
    return TransportService.put(`business/${businessId}/sites/${siteId}`, config)
      .then((response) => SiteConfigModel.fromServer(response));
  }

  static updateSite(businessId: number, siteId: number, config: SiteConfigModel): Promise<SiteModel> {
    return TransportService.put(`business/${businessId}/sites/${siteId}`, config)
      .then((response) => SiteModel.fromServer(response));
  }

  static setCustomSiteStyles(businessId: number, siteId: number, styles: string, isPreview?: boolean): Promise<boolean> {
    const request = new CustomSiteStylesRequest(styles, isPreview);

    return TransportService.put(`business/${businessId}/sites/${siteId}/styles`, request)
      .then((data: { result: boolean, message: string }) => data.result);
  }

  static getCustomSiteStyles(domain: string, isPreview?: boolean): Promise<string> {
    return TransportService.get(`business/sites/styles?domain=${domain}${isPreview ? '&preview=1' : ''}&type=text`)
  }

  static publishSite(businessId: number, siteId: number): Promise<PublishSiteResponse> {
    return TransportService.post(`business/${businessId}/sites/${siteId}/publish`)
      .then((data: object) => PublishSiteResponse.fromServer(data));
  }

  static uploadImage(file: File): Promise<PictureModel> {
    const formData = new FormData();
    formData.append('image_file', file);

    return TransportService.uploadFile('picture/upload', formData)
      .then((response: object) => PictureModel.fromServer(response));
  }

  static getPictureByName(pictureName: string): Promise<PictureModel> {
    return TransportService.get(`picture/get-by-name?name=${pictureName}`)
      .then(response => PictureModel.fromServer(response));
  }
}
