import { BaseModel } from 'api/models/base.model';
import { JsonArray, JsonName } from 'tserialize';
import { SlideBlockModel } from './slide-block.model';

export class GalleryBlockModel extends BaseModel {
  @JsonName('items_quantity_per_slide')
  itemsQuantityPerSlide?: number;

  @JsonArray(SlideBlockModel)
  slides!: Array<SlideBlockModel>

  constructor(data: object) {
    super(data);

    Object.assign(this, data);
  }

  updateBlock(blockConfig?: SlideBlockModel, blockIndex?: number): void {
    if (!blockConfig || blockIndex === undefined || !this.slides) return;

    if (blockIndex < this.slides.length) {
      this.slides[blockIndex] = blockConfig;
    } else {
      this.addBlock(blockConfig, blockIndex)
    }

    this.updateSlides()
  }

  addBlock(blockConfig?: SlideBlockModel, blockIndex?: number) {
    if (!blockConfig) return;

    if (!this.slides) {
      this.slides = [];
    }

    if (blockIndex === undefined || blockIndex >= this.slides.length) {
      this.slides.push(blockConfig);
      return;
    }

    this.slides.splice(blockIndex, 0, blockConfig)
    this.updateSlides()
  }

  removeBlock(blockIndex?: number) {
    if (blockIndex === undefined || !this.slides) return;

    this.slides.splice(blockIndex, 1);
    this.updateSlides()
  }

  moveBlockUp(blockIndex?: number) {
    if (blockIndex === undefined || !this.slides || blockIndex - 1 < 0) return;

    const block = this.slides[blockIndex];
    this.slides[blockIndex] = this.slides[blockIndex - 1];
    this.slides[blockIndex - 1] = block;

    this.updateSlides()
  }

  moveBlockDown(blockIndex?: number) {
    if (blockIndex === undefined || !this.slides || blockIndex + 1 > this.slides.length - 1) return;

    const block = this.slides[blockIndex];
    this.slides[blockIndex] = this.slides[blockIndex + 1];
    this.slides[blockIndex + 1] = block;

    this.updateSlides()
  }

  private updateSlides() {
    this.slides = [...this.slides || []];
  }
}
