import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { changeDirectionToLTR, changeDirectionToRTL } from 'redux/actions/rtlActions';
import { useDispatch } from 'react-redux';

const languages = ['ru', 'he', 'en'];
const languageTitle = {
  ru: 'RU',
  he: 'HEB',
  en: 'EN'
};
const flag = {
  ru: `${process.env.PUBLIC_URL}/img/language/ru.svg`,
  he: `${process.env.PUBLIC_URL}/img/language/il.svg`,
  en: `${process.env.PUBLIC_URL}/img/language/gb.svg`
}

const TopbarLanguage = () => {
  const detectedLanguage = localStorage.getItem('i18nextLng');
  const [selectedLanguage, setSelectedLanguage] = useState(detectedLanguage);
  const { i18n } = useTranslation('common');
  const [isCollapsed, setIsCollapsed] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const language = selectedLanguage || detectedLanguage || 'en';
    i18n.changeLanguage(language)
    language === 'he' ? dispatch(changeDirectionToRTL()) : dispatch(changeDirectionToLTR());
  }, [detectedLanguage, selectedLanguage, dispatch, i18n])

  const toggleLanguage = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed]);

  const changeLanguage = (lng) => {
    toggleLanguage();
    setSelectedLanguage(lng);
  };

  return (
    <div className="topbar__collapse topbar__collapse--language">
      <button className="topbar__btn" type="button" onClick={toggleLanguage}>
         <span className="topbar__language-btn-title">
          <img src={flag[selectedLanguage]} alt={languageTitle[selectedLanguage]} />
          <span>{languageTitle[selectedLanguage]}</span>
        </span>
        <DownIcon className="topbar__icon" />
      </button>
      {isCollapsed && (
        <button
          className="topbar__back"
          type="button"
          aria-label="language button"
          onClick={toggleLanguage}
        />
      )}
      <Collapse
        isOpen={isCollapsed}
        className="topbar__collapse-content topbar__collapse-content--language"
      >
        {languages.map((lng) => {
          return (
            <button
              className="topbar__language-btn"
              type="button"
              onClick={() => changeLanguage(lng)}
            >
              <span className="topbar__language-btn-title">
                <img src={flag[lng]} alt={languageTitle[lng]} />
                <span>{languageTitle[lng]}</span>
              </span>
            </button>
          );
        })}
      </Collapse>
    </div>
  );
};

export default TopbarLanguage;
