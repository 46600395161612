export enum PAGE {
  LOGIN = 'LOGIN',
  BUSINESS_CHOICE = 'BUSINESS_CHOICE',
  SITE_CHOICE = 'SITE_CHOICE',
  SITE_CREATION = 'SITE_CREATION',
  CONSTRUCTOR = 'CONSTRUCTOR',
  SITE_SETTINGS = 'SITE_SETTINGS',
  CUSTOM_STYLES_SETTINGS = 'CUSTOM_STYLES_SETTINGS'
}

export enum PAGE_BLOCK_SETTINGS {
  SITE_COVER_SETTINGS = 'SITE_COVER_SETTINGS',
  SITE_SHOWCASE_SETTINGS = 'SITE_SHOWCASE_SETTINGS',
  SITE_ROUTE_SETTINGS = 'SITE_ROUTE_SETTINGS',
  SITE_MILESTONE_SETTINGS = 'SITE_MILESTONE_SETTINGS',
  SITE_TEXT_BLOCK_SETTINGS = 'SITE_TEXT_BLOCK_SETTINGS',
  SITE_HTML_BLOCK_SETTINGS = 'SITE_HTML_BLOCK_SETTINGS',
  SITE_MENU_ANCHOR_BLOCK_SETTINGS = 'SITE_MENU_ANCHOR_BLOCK_SETTINGS',
  SITE_GALLERY_BLOCK_SETTINGS = 'SITE_GALLERY_BLOCK_SETTINGS',
  SITE_EMPTY_SPACE_BLOCK_SETTINGS = 'SITE_EMPTY_SPACE_BLOCK_SETTINGS'
}
