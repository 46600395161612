import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import { LogOut, LogIn, GoToBusinessesList } from './components';
import avatarRender from './avatarRender';

const TopbarProfile = () => {
  const isLoggedIn = useSelector((store) => store.auth.isLoggedIn);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const currentBusiness = useSelector((store) => store.business.currentBusiness);
  const currentUser = useSelector((store) => store.user.currentUser);

  const { fullName } = currentUser || {};

  return (
    <div className="topbar__profile">
      {!isLoggedIn && <LogIn/>}
      {isLoggedIn && <>
        <button type="button" className="topbar__avatar" onClick={setIsCollapsed}>
          { avatarRender(currentUser) }
          <p className="topbar__avatar-name">
            {fullName}<span> {currentBusiness ? `(${currentBusiness.title})` : null}</span></p>
          <DownIcon className="topbar__icon" />
        </button>

        {isCollapsed && (
          <button
            type="button"
            aria-label="button collapse"
            className="topbar__back"
            onClick={handleToggleCollapse}
            />
          )}
          <Collapse
            isOpen={isCollapsed}
            className="topbar__menu-wrap"
            onClick={handleToggleCollapse}
          >
            <div className="topbar__menu">
              {isLoggedIn && <GoToBusinessesList />}
              {isLoggedIn && <LogOut />}
            </div>
        </Collapse>
      </>}
    </div>
  );
};

export default TopbarProfile;
