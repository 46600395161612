import { ErrorModel, SiteBlockModel, SiteModel } from 'api/models';
import { ThunkAction } from 'redux-thunk';
import { clearPages, loadPageDetails, loadPagesAndSetDefault, loadPagesList } from './page.actions';
import { TStore } from 'redux/store';
import { Action } from 'redux';
import ApiService from 'api/api.service';

export enum SiteActionType {
  SET_SITES = 'SET_SITES',
  SITES_ERROR = 'SITES_ERROR',
  CHOOSE_SITE = 'CHOOSE_SITE',
  CREATE_SITE = 'CREATE_SITE',
  CLEAR_CURRENT_SITE = 'CLEAR_CURRENT_SITE',
  SITE_REQUEST_STARTED = 'SITE_REQUEST_STARTED',
  SITE_REQUEST_FINISHED = 'SITE_REQUEST_FINISHED',
  ADD_SITE_BLOCK = 'ADD_SITE_BLOCK',
  REMOVE_SITE_BLOCK = 'REMOVE_SITE_BLOCK',
  UPDATE_SITE_BLOCK = 'UPDATE_SITE_BLOCK',
  PUBLISH_SITE = 'PUBLISH_SITE',
  MOVE_BLOCK_UP = 'MOVE_BLOCK_UP',
  MOVE_BLOCK_DOWN = 'MOVE_BLOCK_DOWN'
}

export type TSiteAction = {
  type: SiteActionType;
  sites?: Array<SiteModel>;
  error?: ErrorModel;
  currentSite?: SiteModel;
  updatedSite?: SiteModel;
  block?: SiteBlockModel;
  blockIndex?: number;
};

export function setSites(sites: Array<SiteModel>) {
  return { type: SiteActionType.SET_SITES, sites };
}

export function startSiteRequest() {
  return { type: SiteActionType.SITE_REQUEST_STARTED };
}

export function startSiteFinished() {
  return { type: SiteActionType.SITE_REQUEST_FINISHED };
}

export function sitesError(error: ErrorModel) {
  return { type: SiteActionType.SITES_ERROR, error };
}

export function chooseSite(currentSite: SiteModel) {
  return { type: SiteActionType.CHOOSE_SITE, currentSite };
}

export function laodSiteDetail(businessId: number, siteId: number): ThunkAction<void, TStore, unknown, Action> {
  return async dispatch => {
    dispatch(startSiteRequest());
    const siteDetail = await ApiService.getSiteDetail(businessId, siteId);
    dispatch(chooseSite(siteDetail));
    dispatch(startSiteFinished());
  }
}

export function loadSiteAndPages(currentSite: SiteModel): ThunkAction<void, TStore, unknown, Action> {
  return async dispatch => {
    try {
      dispatch(laodSiteDetail(currentSite.businessId, currentSite.id));
      dispatch(loadPagesAndSetDefault(currentSite.businessId, currentSite.id, '/'));
    } catch (error) {
      dispatch(sitesError(error as ErrorModel));
    } finally {
      dispatch(startSiteFinished());
    }
  }
}

export function clearSiteAndPages(): ThunkAction<void, TStore, unknown, Action> {
  return dispath => {
    dispath(clearCurrentSite);
    dispath(clearPages);
  };
} 

export function createSite(currentSite: SiteModel) {
  return { type: SiteActionType.CREATE_SITE, currentSite };
}

export function clearCurrentSite() {
  return { type: SiteActionType.CLEAR_CURRENT_SITE };
}

export function addSiteBlock(block: SiteBlockModel, blockIndex?: number) {
  return { type: SiteActionType.ADD_SITE_BLOCK, block, blockIndex };
}

export function removeSiteBlock(blockIndex?: number) {
  return { type: SiteActionType.REMOVE_SITE_BLOCK, blockIndex };
}

export function updateSiteBlock(block: SiteBlockModel, blockIndex?: number) {
  return { type: SiteActionType.UPDATE_SITE_BLOCK, block, blockIndex };
}

export function moveBlockUp(blockIndex: number) {
  return { type: SiteActionType.MOVE_BLOCK_UP, blockIndex };
}

export function moveBlockDown(blockIndex?: number) {
  return { type: SiteActionType.MOVE_BLOCK_DOWN, blockIndex };
}

export function publishSite(currentSite: SiteModel) {
  return { type: SiteActionType.PUBLISH_SITE, currentSite };
}