import { JsonName } from "tserialize";
import { BaseModel } from "./models/base.model";

import { deserialize } from "tserialize";

export type TTranslatableData<T> = Record<string, T>;

// Интерфейс, описывающий, что класс должен иметь статический метод fromServer
interface Deserializable<T> {
  new (): T;
  fromServer?: (data: object) => T;
}

export function generateTranslatableDeserializer<T extends BaseModel>(Cls: Deserializable<T>): (rawValue: TTranslatableData<Partial<T>>) => TTranslatableData<T> {
  return (rawValue: TTranslatableData<Partial<T>>): TTranslatableData<T> => {
    const result: TTranslatableData<T> = {};

    for (const [key, value] of Object.entries(rawValue)) {
      if (typeof Cls.fromServer === 'function') {
        result[key] = Cls.fromServer(value);
      } else {
        result[key] = deserialize<T>(value, Cls);
      }
    }

    return result;
  }
}

export const JsonTranslatable = <T extends BaseModel>(TargetClass: Deserializable<T>, rawName?: string) =>
  JsonName<TTranslatableData<T>>(rawName, undefined, generateTranslatableDeserializer<T>(TargetClass));
