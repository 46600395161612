import { BaseModel } from 'api/models/base.model';
import { JsonName } from 'tserialize';

export class CustomSiteStylesRequest extends BaseModel {
  @JsonName('preview')
  isPreview?: boolean;

  @JsonName()
  styles!: string;

  constructor(styles: string, isPreview?: boolean) {
    super({ styles, isPreview });

    this.styles = styles;
    this.isPreview = isPreview;
  }
}