import { AuthActionType, TAuthAction } from 'redux/actions/auth.action';
import { ErrorModel } from "api/models/error.model";

export type TAuthState = { 
  isLoggedIn: boolean; 
  isLoading: boolean;
  token?: string;
  error?: ErrorModel | null;
};

const authDefaults: TAuthState = { isLoggedIn: false, token: '', isLoading: false };
const storedState: TAuthState = localStorage.auth ? JSON.parse(localStorage.auth) : {};
const initialState: TAuthState = {
  isLoggedIn: storedState.isLoggedIn || authDefaults.isLoggedIn,
  token: storedState.token || authDefaults.token,
  isLoading: storedState.isLoading || authDefaults.isLoading,
};

export const authReducer = (state: TAuthState = initialState, action: TAuthAction): TAuthState => {
  switch (action.type) {
    case AuthActionType.AUTHENTICATE_REQUEST_STARTED:
      return { ...state, isLoading: true, error: null  }
    case AuthActionType.AUTHENTICATE_LOGIN:
      return { ...state, isLoggedIn: true, isLoading: false, token: action.token };
    case AuthActionType.AUTHENTICATE_ERROR_AUTH:
      return { ...state, isLoading: false, error: action.error };
    case AuthActionType.AUTHENTICATE_LOGOUT:
      localStorage.removeItem('auth');
      localStorage.removeItem('currentUser');
      localStorage.removeItem('context');
      return { ...state, ...authDefaults };
    default:
      return state;
  }
};
