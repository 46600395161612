import { 
  ChangeDirectionActionType, 
  TChangeDirectionAction 
} from 'redux/actions/rtlActions';

type TDirectionState = { direction: 'ltr' | 'rtl' };

const initialDirectionState: TDirectionState = { direction: 'ltr' };

export const rtlReducer = (state: TDirectionState = initialDirectionState, action: TChangeDirectionAction): TDirectionState => {
  switch (action.type) {
    case ChangeDirectionActionType.CHANGE_DIRECTION_TO_LTR:
      document.documentElement.dir = 'ltr';
      return { direction: 'ltr' };
    case ChangeDirectionActionType.CHANGE_DIRECTION_TO_RTL:
      document.documentElement.dir = 'rtl';
      return { direction: 'rtl' };
    default:
      return state;
  }
};
