import { BehaviorSubject } from 'rxjs';
import { LoginPage } from './login-page.enum';

class LoginService {
  currentPage$ = new BehaviorSubject<LoginPage>(LoginPage.LOGIN);

  setCurrentLoginPageCallback(page: LoginPage): VoidFunction {
    return () => this.currentPage$.next(page);
  }
}

export const loginService = new LoginService();