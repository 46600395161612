import { ErrorModel } from 'api/models/error.model';

export enum AuthActionType {
  AUTHENTICATE_REQUEST_STARTED = 'AUTHENTICATE_REQUEST_STARTED',
  AUTHENTICATE_LOGIN = 'AUTHENTICATE_LOGIN',
  AUTHENTICATE_ERROR_AUTH = 'AUTHENTICATE_ERROR_AUTH',
  AUTHENTICATE_LOGOUT = 'AUTHENTICATE_LOGOUT'
}

export type TAuthAction = { 
  type: AuthActionType; 
  token?: string; 
  error?: ErrorModel | null;
};

export function authRequested() {
  return { type: AuthActionType.AUTHENTICATE_REQUEST_STARTED };
}

export function login(token: string) {
  return { type: AuthActionType.AUTHENTICATE_LOGIN, token };
}

export function authError(error: ErrorModel) {
  return { type: AuthActionType.AUTHENTICATE_ERROR_AUTH, error };
}

export function logout() {
  return { type: AuthActionType.AUTHENTICATE_LOGOUT };
}
