import React, { MouseEventHandler, PropsWithChildren, useCallback } from "react";
import { Button as RbButton } from 'reactstrap';
import { Spinner } from "ui-kit/spinner";
import './button.scss';

type TButtonProps = PropsWithChildren<{
  disabled?: boolean;
  outline?: boolean;
  type?: 'square' | 'rounded' | 'icon';
  color?: 'primary' | 'success' | 'warning' | 'danger';
  size?: 'sm' | 'lg';
  onClick: MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  customRef?: React.RefObject<HTMLButtonElement>;
}>;

export function Button({ 
  disabled, 
  outline, 
  type, 
  color, 
  size, 
  onClick, 
  isLoading,
  customRef, 
  children 
}: TButtonProps): JSX.Element {
  const preventDoubleClick = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();
    event.bubbles = false;
  }, []);

  return (
    <RbButton
      className={type}
      onClick={preventDoubleClick}
      disabled={disabled}
      outline={outline}
      size={size}
      onClickCapture={onClick}
      color={color}
      innerRef={customRef}
    >
      {isLoading
        ? <Spinner buttonSpinner />
        : <p onClick={preventDoubleClick}>{children}</p>
      }
    </RbButton>
  );
}