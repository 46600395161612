import {BaseModel} from "../base.model";
import {JsonName} from "tserialize";
import { TLText } from 'types/text';

export type TAnchorBlockData = {
  text: TLText;
  title?: TLText;
  id: string;
};

export class MenuAnchorBlockModel extends BaseModel {
  @JsonName()
  text!: TLText;

  @JsonName()
  title?: TLText;

  @JsonName()
  id!: string;

  constructor(data: TAnchorBlockData) {
    super(data);

    Object.assign(this, data);
  }
}
