import { JsonName } from 'tserialize';
import { BaseModel } from "./base.model";

export class LoginRequest extends BaseModel {
  @JsonName()
  email!: string;
  
  @JsonName()
  password!: string;

  constructor(email: string, password: string) {
    super({ email, password });

    this.email = email;
    this.password = password;
  }
}
