import { useEffect, useState } from 'react';
import { Observable } from 'rxjs';

export function useRx<T>(data$: Observable<T>, defaultValue?: T) {
  const [fieldValue, setFieldValue] = useState<T>();

  useEffect(() => {
    const subscription = data$.subscribe((value) => {
      setFieldValue(value);
    });

    return () => subscription.unsubscribe();
  }, [data$])

  return fieldValue || defaultValue;
}
