import { JsonName } from "tserialize";
import { BaseModel } from "./base.model";

export class MilestoneModel extends BaseModel {
  @JsonName()
  id!: number;

  @JsonName('track_id')
  trackId!: number;

  @JsonName()
  latitude!: number;

  @JsonName()
  longitude!: number;

  @JsonName('timestamp_from')
  timestampFrom!: number;

  @JsonName('timestamp_to')
  timestampTo!: number;

  @JsonName('segment_action_type')
  segmentActionType!: string;

  @JsonName('segment_speed')
  segmentSpeed!: number;

  @JsonName('segment_duration')
  segmentDuration!: number;

  @JsonName('segment_distance')
  segmentDistance!: number;

  @JsonName('google_place_id')
  googlePlaceId?: string;

  @JsonName('fixed_for_dynamic')
  fixedForDynamic?: boolean;

  @JsonName()
  title!: string;

  @JsonName()
  description!: string;

  @JsonName('is_edited')
  isEdited!: boolean;

  @JsonName()
  game!: boolean;

  @JsonName('game_answer')
  gameAnswer!: string;

  @JsonName('panorama_enabled')
  panoramaEnabled!: boolean;

  @JsonName('youtube_enabled')
  youtubeEnabled!: boolean;

  @JsonName()
  code!: string;

  @JsonName()
  visitable!: boolean;
}
