import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { TStore } from 'redux/store';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import EmailIcon from 'mdi-react/EmailIcon';
import { Offset } from 'ui-kit/offset';
import { Button } from 'ui-kit/button';
import { loginFormService } from './login-form.service';
import { Input, Control, ControlButton, ControlIcon } from 'utils/form';
import { Grid } from 'ui-kit/grid';
import { Link } from 'ui-kit/link';
import { LoginPage } from '../../login-page.enum';
import { loginService } from '../../login.service';
import { useRx } from 'utils/hooks/use-rx';
import { useTranslation } from 'react-i18next';

export function LoginForm (): JSX.Element {
  const isLoading = useSelector((store: TStore) => store.auth.isLoading);
  const isFullValid = useRx(loginFormService.isFullValid$(), false);
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const handleShowPassword = useCallback(() => {
    setIsPasswordShown(!isPasswordShown);
  }, [isPasswordShown]);

  const { t } = useTranslation('common');

  return <>
    <Control
      label={t('modals.login.formLabels.email')}
      error={loginFormService.getError$('email')}
      leftAcc={<ControlIcon><EmailIcon/></ControlIcon>}
    >
      <Input fieldName="email" service={loginFormService} placeholder={t('modals.login.placeholderEmail')}/>
    </Control>

    <Offset offset="20"/>

    <Control
      label={t('modals.login.formLabels.password')}
      error={loginFormService.getError$('password')}
      leftAcc={<ControlIcon><KeyVariantIcon/></ControlIcon>}
      rightAcc={(
        <ControlButton onClick={() => handleShowPassword()} isActive={isPasswordShown}>
          <EyeIcon/>
        </ControlButton>
      )}
    >
      <Input
        fieldName="password"
        service={loginFormService}
        type={isPasswordShown ? 'text' : 'password'}
        placeholder={t('modals.login.placeholderPassword')}
      />
    </Control>
    
    <Offset offset="4"/>

    <Grid justify="flex-end">
      <Link href="https://web.test.pinsteps.com/forgot-password">
      <p>{t('modals.login.forgotPassword')}</p>
      </Link>
    </Grid>

    <Offset offset="35"/>

    <Grid gap={8}>
      <Button onClick={loginService.setCurrentLoginPageCallback(LoginPage.REGISTRATION)}>{t("buttons.signUp")}</Button>

      <Button color="primary" disabled={!isFullValid} isLoading={isLoading} onClick={loginFormService.handleLogin}>
        {t("buttons.signIn")}
      </Button>
    </Grid>
  </>;
}
