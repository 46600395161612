import { BaseModel } from 'api/models/base.model';
import { JsonName } from 'tserialize';

export type TRegisterUserRequest = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  nickname: string;
  description?: string;
}

export class RegisterUserRequest extends BaseModel {
  @JsonName()
  email!: string;

  @JsonName()
  password!: string;

  @JsonName('first_name')
  firstName!: string

  @JsonName('last_name')
  lastName!: string;

  @JsonName()
  nickname!: string;

  @JsonName()
  description!: string;

  constructor(data: TRegisterUserRequest) {
    super(data);

    Object.assign(this, data)
  }
}