import { JsonName, JsonNameLate } from 'tserialize';
import { SiteBlock } from "enums/site-block.enum";
import { BaseModel } from "./base.model";
import {
  CoverBlockModel,
  GalleryBlockModel,
  MilestoneBlockModel,
  PlaneHtmlBlockModel,
  RouteBlockModel,
  ShowcaseBlockModel,
  TextBlockModel,
  MenuAnchorBlockModel,
  EmptySpaceBlockModel
} from './blocks';

export type TSiteBlockContent = RouteBlockModel
  | MilestoneBlockModel
  | ShowcaseBlockModel
  | CoverBlockModel
  | TextBlockModel
  | PlaneHtmlBlockModel
  | MenuAnchorBlockModel
  | GalleryBlockModel
  | EmptySpaceBlockModel;

const MODELS: Record<SiteBlock, typeof BaseModel> = {
  [SiteBlock.ROUTE]: RouteBlockModel,
  [SiteBlock.MILESTONE]: MilestoneBlockModel,
  [SiteBlock.SHOWCASE]: ShowcaseBlockModel,
  [SiteBlock.COVER]: CoverBlockModel,
  [SiteBlock.TEXT]: TextBlockModel,
  [SiteBlock.HTML]: PlaneHtmlBlockModel,
  [SiteBlock.ANCHOR]: MenuAnchorBlockModel,
  [SiteBlock.GALLERY]: GalleryBlockModel,
  [SiteBlock.EMPTY_SPACE]: EmptySpaceBlockModel
};

function deserializeContent(value: object, data: SiteBlockModel): TSiteBlockContent {
  return MODELS[data.type].fromServer(value);
}

function serializeContent(value: TSiteBlockContent, data: SiteBlockModel): object {
  return new MODELS[data.type]({ ...value }).toServer();
}

export class SiteBlockModel extends BaseModel {
  @JsonName()
  type!: SiteBlock;

  @JsonNameLate('content', serializeContent, deserializeContent)
  content!: TSiteBlockContent;

  constructor(data: object) {
    super(data);

    Object.assign(this, data);
  }
}
