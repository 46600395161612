import { useForm } from './use-form';
import React, { InputHTMLAttributes } from 'react';
import { FormService, TFormService } from 'utils/form/form.service';
import { Grid } from 'ui-kit/grid';

type TFormInputProps<T extends TFormService<T>> = InputHTMLAttributes<HTMLInputElement>
& { service: FormService<T>; fieldName: keyof T, withLanguage?: boolean};

export function Input<T extends TFormService<T>>(
  { service, fieldName, onFocus, onChange, onBlur, withLanguage, ...rest }: TFormInputProps<T>
): JSX.Element {
  const { value, onFocusFn, onChangeFn, onBlurFn, languageButtons, disabled } = useForm({ service, fieldName, onFocus, onChange, onBlur, withLanguage });

  return <Grid justify="space-between">
    <input
      {...rest}
      value={value as unknown as string || ''}
      onFocus={onFocusFn}
      onChange={onChangeFn}
      onBlur={onBlurFn}
      disabled={disabled}
    />

    {languageButtons}
  </Grid>;
}
