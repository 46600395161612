import { FormService } from 'utils/form';
import ApiService from 'api/api.service';
import { BehaviorSubject } from 'rxjs';
import { openErrorAlert } from 'utils/alert.service';
import { requireValidator } from 'utils';
import { loginFormService } from '../login-form/login-form.service';
import i18n from 'translations/i18n';

const initialState = { email: '', password: '', firstName: '', lastName: '', nickname: '', description: '' };
const validators = {
  email: (email: string) => /.+@.+\..+/.test(email) ? '' : i18n.t('common:modals.registration.formErrors.email'),
  password: requireValidator(i18n.t('common:modals.registration.formErrors.password')),
  firstName: requireValidator(i18n.t('common:modals.registration.formErrors.firstName')),
  lastName: requireValidator(i18n.t('common:modals.registration.formErrors.lastName')),
  nickname: requireValidator(i18n.t('common:modals.registration.formErrors.nickname')),
};

class RegistrationFormService extends FormService<typeof initialState> {
  isLoading$ = new BehaviorSubject<boolean>(false);

  registerUser = (): void => {
    this.isLoading$.next(true);
    const values = this.formValues$.getValue();

    void ApiService.registerUser(values)
      .then(() => {
          loginFormService.setValue('email', values.email);
          loginFormService.setValue('password', values.password);

          return loginFormService.handleLogin()
      })
      .catch(() => openErrorAlert('Failed to create a User'))
      .finally(() => this.isLoading$.next(false));
  };
}

export const registrationFormService = new RegistrationFormService(initialState, validators);
