import React from 'react';

const Logo = () => {
  
  return (
    <div className='topbar__logo'>
      <a 
        href="https://pinsteps.com/" 
        target="_blank" 
        rel="noreferrer"
      >
        <div className='topbar__logo__picture' />
      </a>
    </div>
  );
};

export default Logo;
